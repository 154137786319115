import Axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { useAuth0 } from "@auth0/auth0-react";
import { errorToast } from "../../utils/toast-utils";
import { UserDbDataState } from "./user-db-state";
import { DbUser } from "../../models/user";

const initialState: UserDbDataState = {
    user: undefined,
    userIsFetching: false,
    userHasError: false,
}

//get user
export const getUserDbAsync = createAsyncThunk(
    'userDb',
    async () => {
        const { getAccessTokenSilently, user } = useAuth0();
        const userId = user?.sub;
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<DbUser[]>(`${serverUrl}api/user/${userId}`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const userDbSlice = createSlice({
    name: 'userDb',
    initialState,
    reducers: {
        addUserDb: (state, action: PayloadAction<DbUser>) => {
            state.user?.push(action.payload);
        },
    },
    // pending
    // fulfilled
    // rejected
    extraReducers: (builder) => {
        builder
            .addCase(getUserDbAsync.pending, (state) => {
                state.userIsFetching = true;
                state.userHasError = false;
            })
            .addCase(getUserDbAsync.fulfilled, (state, action) => {
                state.userIsFetching = false;
                state.user = action.payload;
            })
            .addCase(getUserDbAsync.rejected, (state, action) => {
                state.userIsFetching = false;
                state.userHasError = true;
            })
    },
});

export const selectUserDb = (state: RootState) => state.user.user;
export const selectUserDbIsFetching = (state: RootState) => state.user.userIsFetching;
export const selectUserDbHasError = (state: RootState) => { return state.user.userHasError };

export const {addUserDb} = userDbSlice.actions;

export default userDbSlice.reducer;