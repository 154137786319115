import "./faq-view.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import { useContentful } from "react-contentful";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useState } from "react";


export function FaqView() {
    const [questions, setQuestions] = useState([] as { questionTitle: string, answerContent: string }[]);
    const { data, error, fetched, loading } = useContentful({
        contentType: 'faqPageContent',
    });

    if (!error && !loading && fetched) {
        if (fetched && data && questions.length === 0) {
            const options = {
                renderText: text =>
                    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
            };

            let newQuestions = [] as { questionTitle: string, answerContent: string }[];
            if ((data as any).items.length > 0 && (data as any).items[0].fields.lineItems && (data as any).items[0].fields.lineItems.length > 0) {
                for (let lineItem of (data as any).items[0].fields.lineItems) {
                    let questionTitle = lineItem.fields.headingQuestion as string;
                    let answerContent = documentToHtmlString(lineItem.fields.answerContent, options as any)
                    answerContent = answerContent.replace(/\n/g, '<br/>');
                    newQuestions.push({ questionTitle, answerContent });
                }
            }
            setQuestions(newQuestions);
        }
    }

    return (
        <>
            <Col>
                <Row className="w-50"><h1>FAQ</h1></Row>
                {questions.length > 0 ?
                    <>
                        {questions.map(question => (
                            <Row className="w-75 mb-2">

                                <Card >
                                    <Card.Body>
                                        <Card.Title><b>{question.questionTitle}</b></Card.Title>
                                        <Card.Text dangerouslySetInnerHTML={{ __html: question.answerContent }} />
                                    </Card.Body>
                                </Card>
                            </Row>
                        ))}
                    </>
                    : <></>}
            </Col>
        </>
    )
}
