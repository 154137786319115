import "./home-page.scss"

import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { HighlightsReel } from "./highlights-reel/highlights-reel"
import Row from "react-bootstrap/Row"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useContentful } from "react-contentful";
import { useState } from "react";

export function HomePage() {
    const [title, setTitle] = useState('Welcome to the Maluti-X Solutions Portal');
    const [content, setContent] = useState('');
    const [mainLogoUrl, setMainLogoUrl] = useState('');
    const [mediaUrls, setMediaUrls] = useState([] as string[]);
    const { data, error, fetched, loading } = useContentful({
        contentType: 'homePageContent',
    });


    if (!error && !loading && fetched && mainLogoUrl === '') {
        if (fetched && data && content === '') {
            setTitle((data as any).items[0].fields.heading);
            // TODO: Figure out how to use this because it should work but doesn't
            setMainLogoUrl(`https:${(data as any).items[0].fields.mainLogo.fields.file.url}`)
            const options = {
                renderText: text =>
                    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
            };

            let newContent = documentToHtmlString((data as any).items[0].fields.content, options as any)
            newContent = newContent.replace(/\n/g, '<br/>');
            setContent(newContent);

            let newMediaUrls = [] as string[]
            if ((data as any).items.length > 0 && (data as any).items[0].fields.promotionalImages && (data as any).items[0].fields.promotionalImages.length > 0) {
                for (let mediaItem of (data as any).items[0].fields.promotionalImages) {
                    newMediaUrls.push(`https:${mediaItem.fields.file.url}`);
                }
            }
            setMediaUrls(newMediaUrls);
        }
    }

    return (
        <div className="home-page">
            <div className="image-row">
                <img src={mainLogoUrl} className="centered-image"></img>
            </div>
            <div className="text-row" >
                <p className="centered-text" dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
            {/* <div className="card-row">
                <HighlightsReel/>
            </div> */}
            
            {/* {mediaUrls.length > 0 ?
                <Row>
                    <Card className='m-auto p-3' style={{ width: 'fit-content' }}>
                        <Row>
                            {mediaUrls.map(url => (
                                <Col>
                                    <Card >
                                        <Card.Img variant="top" src={url} className='home-page-img' />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                </Row>
                :
                <></>
            } */}

        </div>
    )
}
