import "./pricing-structure.scss"
import { AddPricingStructureModal } from "./add-pricing-structure-modal/add-pricing-structure-modal"
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectPricingStructures, selectPricingStructuresError, selectPricingStructuresIsFetching, getPricingStructuresAsync } from "../../../slices/apex-data/apex-data-slice";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Spinner from 'react-bootstrap/Spinner';

export function PricingStructureView() {
    const pricingStructures = useAppSelector(selectPricingStructures);
    const isFetching = useAppSelector(selectPricingStructuresIsFetching);
    const error = useAppSelector(selectPricingStructuresError);
    const dispatch = useAppDispatch();

    if (!isFetching && pricingStructures === undefined && !error) {
        dispatch(getPricingStructuresAsync());
    }
    return (
        <Container fluid>
            <br/>
            <Row className="w-100">
                <Col md={6}>
                    <h1>Pricing Structure</h1>
                </Col>
                <Col md={{ span: 3, offset: 3 }} className="d-flex">
                    <AddPricingStructureModal></AddPricingStructureModal>
                </Col>
            </Row>
            <br />
            <h3>Generic</h3>
            {!isFetching && pricingStructures !== undefined ? (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Currency</th>
                            <th>Credit Cost</th>
                            <th>From</th>
                            <th>Until</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pricingStructures.filter(item => !item.companyName).map(item => (
                            <tr>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>{item.currency}</td>
                                <td>{item.creditCost}</td>
                                <td>{item.fromDate}</td>
                                <td>{item.untilDate ?? "Currently Active"}</td>
                            </tr>

                        ))}
                    </tbody>
                </Table>
            ) : (
                <Spinner animation="border" variant="dark" role="status">
                    <   span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
            <br />
            <h3>Company</h3>
            {!isFetching && pricingStructures !== undefined ? (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Company</th>
                            <th>Currency</th>
                            <th>Credit Cost</th>
                            <th>From</th>
                            <th>Until</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pricingStructures.filter(item => item.companyName).map(item => (
                            <tr>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>{item.companyName}</td>
                                <td>{item.currency}</td>
                                <td>{item.creditCost}</td>
                                <td>{item.fromDate}</td>
                                <td>{item.untilDate ?? "Currently Active"}</td>
                            </tr>

                        ))}
                    </tbody>
                </Table>
            ) : (
                <Spinner animation="border" variant="dark" role="status">
                    <   span className="visually-hidden">Loading...</span>
                </Spinner>
            )}

        </Container>
    )
}
