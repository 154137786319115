import "./category-button.scss"
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FoundationModuleCategory } from "../../models/module";
import { useContentful } from 'react-contentful';

export function CategoryButton(props) {
    const categoryData: FoundationModuleCategory = props.data;
    const { data, error, fetched, loading } = useContentful({
        contentType: 'moduleCategory',
        query: {
            'fields.uniqueIdentifier': categoryData.uniqueIdentifier.toUpperCase(),
        }
    });

    let coloursSet = false;
    let backgroundColour = 'lightgray';
    let textColour = 'gray';

    if (fetched && data && !coloursSet && !error) {
        if ((data as any).items.length > 0) {
            backgroundColour = (data as any).items[0].fields.backgroundColour;
            textColour = (data as any).items[0].fields.textColour;
        }
        coloursSet = true;
    }

    return (
        <>
            {!loading ? (
                <Button variant="none" className="category-button float-right" style={{ color: textColour, background: backgroundColour }}>{categoryData.name}</Button>
            ) : (
                <Spinner animation="border" variant="dark" role="status">
                    <   span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </>
    );
}
