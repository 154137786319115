import Axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { useAuth0 } from "@auth0/auth0-react";
import { errorToast } from "../../utils/toast-utils";
import { TicketDataState } from "./ticket-state";
import { Ticket } from "../../models/ticket";
import { TicketType } from "../../models/ticket-type";
import { TicketResponse } from "../../models/ticket-response";


const initialState: TicketDataState = {
    ticket: undefined,
    ticketIsFetching: false,
    ticketHasError: false,
    userTicket: undefined,
    userTicketIsFetching: false,
    userTicketHasError: false,
    ticketType: undefined,
    ticketTypeIsFetching: false,
    ticketTypeHasError: false,
    ticketResponse: undefined,
    ticketResponseIsFetching: false,   
    ticketResponseHasError: false,
    userTicketResponse: undefined,
    userTicketResponseIsFetching: false,   
    userTicketResponseHasError: false,
    companyTicket: undefined,
    companyTicketIsFetching: false,
    companyTicketHasError: false,
    companyTicketResponse: undefined,
    companyTicketResponseIsFetching: false,   
    companyTicketResponseHasError: false,

}

export const getTicketTypesAsync = createAsyncThunk(
    'tickets/getTicketType',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<TicketType[]>(`${serverUrl}api/ticket/type`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);
//get all tickets for apex use
export const getTicketsAsync = createAsyncThunk(
    'tickets/getTickets',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<Ticket[]>(`${serverUrl}api/ticket`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);
//get tickets specific to a user
export const getUserTicketsAsync = createAsyncThunk(
    'tickets/getUserTickets',
    async () => {
        const { getAccessTokenSilently, user } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<Ticket[]>(`${serverUrl}api/ticket/${user?.email}`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);
//get tickets specific to a company
;
export const getCompanyTicketsAsync = createAsyncThunk(
    'tickets/getCompanyTickets',
    async () => {
        ;
        const { getAccessTokenSilently, user } = useAuth0();
        const companyId = user && user["https://foundation.com/app_metadata"].root_company_identifier ? user["https://foundation.com/app_metadata"].root_company_identifier : "" ;
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<Ticket[]>(`${serverUrl}api/ticket/company/${companyId}`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);
//get all ticket responses
export const getTicketResponsesAsync = createAsyncThunk(
    'tickets/getTicketResponses',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<TicketResponse[]>(`${serverUrl}api/ticket/response/`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);
//get ticket responses relevant to a user
export const getUserTicketResponsesAsync = createAsyncThunk(
    'tickets/getUserTicketResponses',
    async () => {
        const { getAccessTokenSilently, user } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<TicketResponse[]>(`${serverUrl}api/ticket/response/${user?.email}`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);
//get ticket responses relevant to a company
export const getCompanyTicketResponsesAsync = createAsyncThunk(
    'tickets/getCompanyTicketResponses',
    async () => {
        const { getAccessTokenSilently, user } = useAuth0();
        const companyId = user && user["https://foundation.com/app_metadata"].root_company_identifier ? user["https://foundation.com/app_metadata"].root_company_identifier : "" ;
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<TicketResponse[]>(`${serverUrl}api/ticket/company/response/${companyId}`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const ticketSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        addTicketType: (state, action: PayloadAction<TicketType>) => {
            state.ticketType?.push(action.payload);
        },
        addTicket: (state, action: PayloadAction<Ticket>) => {
            state.ticket?.push(action.payload);
        },
        addTicketResponse: (state, action: PayloadAction<TicketResponse>) => {
            state.ticketResponse?.push(action.payload);
        },
        updateTicket: (state, action: PayloadAction<Ticket>) => {
            let index = state.ticket?.findIndex(n => n.uniqueIdentifier === action.payload.uniqueIdentifier);
            if(state.ticket === undefined || index === undefined || index < 0){
                return;
            }
            state.ticket[index] = action.payload;
        },
        removeTicket: ( state, action: PayloadAction<string>)=>{
            let index = state.ticket?.findIndex(n => n.uniqueIdentifier === action.payload);
            if(index === undefined || index < 0){
                return;
            }
            state.ticket?.splice(index, 1);
        },
        updateTicketType: (state, action: PayloadAction<TicketType>) => {
            let index = state.ticketType?.findIndex(n => n.uniqueIdentifier === action.payload.uniqueIdentifier);
            if(state.ticketType === undefined || index === undefined || index < 0){
                return;
            }
            state.ticketType[index] = action.payload;
        }, 
        removeTicketType: ( state, action: PayloadAction<string>)=>{
            let index = state.ticketType?.findIndex(n => n.uniqueIdentifier === action.payload);
            if(index === undefined || index < 0){
                return;
            }
            state.ticketType?.splice(index, 0);
        }
    },
    // pending
    // fulfilled
    // rejected
    extraReducers: (builder) => {
        builder
            .addCase(getTicketTypesAsync.pending, (state) => {
                state.ticketTypeIsFetching = true;
                state.ticketTypeHasError = false;
            })
            .addCase(getTicketTypesAsync.fulfilled, (state, action) => {
                state.ticketTypeIsFetching = false;
                state.ticketType = action.payload;
            })
            .addCase(getTicketTypesAsync.rejected, (state, action) => {
                state.ticketTypeIsFetching = false;
                state.ticketTypeHasError = true;
            })
            .addCase(getTicketsAsync.pending, (state) => {
                state.ticketIsFetching = true;
                state.ticketHasError = false;
            })
            .addCase(getTicketsAsync.fulfilled, (state, action) => {
                state.ticketIsFetching = false;
                state.ticket = action.payload;
            })
            .addCase(getTicketsAsync.rejected, (state, action) => {
                state.ticketIsFetching = false;
                state.ticketHasError = true;
            })
            .addCase(getUserTicketsAsync.pending, (state) => {
                state.userTicketIsFetching = true;
                state.userTicketHasError = false;
            })
            .addCase(getUserTicketsAsync.fulfilled, (state, action) => {
                state.userTicketIsFetching = false;
                state.userTicket = action.payload;
            })
            .addCase(getUserTicketsAsync.rejected, (state, action) => {
                state.userTicketIsFetching = false;
                state.userTicketHasError = true;
            })
            .addCase(getTicketResponsesAsync.pending, (state) => {
                state.ticketResponseIsFetching = true;
                state.ticketResponseHasError = false;
            })
            .addCase(getTicketResponsesAsync.fulfilled, (state, action) => {
                state.ticketResponseIsFetching = false;
                state.ticketResponse = action.payload;
            })
            .addCase(getTicketResponsesAsync.rejected, (state, action) => {
                state.ticketResponseIsFetching = false;
                state.ticketResponseHasError = true;
            })            
            .addCase(getUserTicketResponsesAsync.pending, (state) => {
                state.userTicketResponseIsFetching = true;
                state.userTicketResponseHasError = false;
            })
            .addCase(getUserTicketResponsesAsync.fulfilled, (state, action) => {
                state.userTicketResponseIsFetching = false;
                state.userTicketResponse = action.payload;
            })
            .addCase(getUserTicketResponsesAsync.rejected, (state, action) => {
                state.userTicketResponseIsFetching = false;
                state.userTicketResponseHasError = true;
            })
            .addCase(getCompanyTicketsAsync.pending, (state) => {
                state.companyTicketIsFetching = true;
                state.companyTicketHasError = false;
            })
            .addCase(getCompanyTicketsAsync.fulfilled, (state, action) => {
                state.companyTicketIsFetching = false;
                state.companyTicket = action.payload;
            })
            .addCase(getCompanyTicketsAsync.rejected, (state, action) => {
                state.companyTicketIsFetching = false;
                state.companyTicketHasError = true;
            })
            .addCase(getCompanyTicketResponsesAsync.pending, (state) => {
                state.companyTicketResponseIsFetching = true;
                state.companyTicketResponseHasError = false;
            })
            .addCase(getCompanyTicketResponsesAsync.fulfilled, (state, action) => {
                state.companyTicketResponseIsFetching = false;
                state.companyTicketResponse = action.payload;
            })
            .addCase(getCompanyTicketResponsesAsync.rejected, (state, action) => {
                state.companyTicketResponseIsFetching = false;
                state.companyTicketResponseHasError = true;
            });
    },
});

export const selectTickets = (state: RootState) => state.tickets.ticket;
export const selectTicketsIsFetching = (state: RootState) => state.tickets.ticketIsFetching;
export const selectTicketsError = (state: RootState) => { return state.tickets.ticketHasError };

export const selectTicketTypes = (state: RootState) => state.tickets.ticketType;
export const selectTicketTypesIsFetching = (state: RootState) => state.tickets.ticketTypeIsFetching;
export const selectTicketTypesHasError = (state: RootState) => { return state.tickets.ticketTypeHasError};

export const selectTicketResponses = (state: RootState) => state.tickets.ticketResponse;
export const selectTicketResponseIsFetching = (state: RootState) => state.tickets.ticketResponseIsFetching;
export const selectTicketResponseHasError = (state: RootState) => { return state.tickets.ticketResponseHasError };

export const selectUserTickets = (state: RootState) => state.tickets.userTicket;
export const selectUserTicketsIsFetching = (state: RootState) => state.tickets.userTicketIsFetching;
export const selectUserTicketsHasError = (state: RootState) => { return state.tickets.userTicketHasError };

export const selectUserTicketResponses = (state: RootState) => state.tickets.userTicketResponse;
export const selectUserTicketResponseIsFetching = (state: RootState) => state.tickets.userTicketResponseIsFetching;
export const selectUserTicketResponseHasError = (state: RootState) => { return state.tickets.userTicketResponseHasError };

export const selectCompanyTickets = (state: RootState) => state.tickets.companyTicket;
export const selectCompanyTicketsIsFetching = (state: RootState) => state.tickets.companyTicketIsFetching;
export const selectCompanyTicketsHasError = (state: RootState) => { return state.tickets.companyTicketHasError};

export const selectCompanyTicketResponses = (state: RootState) => state.tickets.companyTicketResponse;
export const selectCompanyTicketResponseIsFetching = (state: RootState) => state.tickets.companyTicketResponseIsFetching;
export const selectCompanyTicketResponseHasError = (state: RootState) => { return state.tickets.companyTicketResponseHasError };

export const { addTicketType, addTicket,addTicketResponse, updateTicketType, removeTicketType, updateTicket, removeTicket } = ticketSlice.actions;

export default ticketSlice.reducer;