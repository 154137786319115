import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Axios from "axios";
import { FoundationModule } from "../../models/module";
import { ModulesState } from "./modules-state";
import { RootState } from '../../app/store';
import { errorToast } from "../../utils/toast-utils";

const initialState: ModulesState = {
    modules: undefined,
    modulesIsFetching: false,
    modulesHasError: false,
    publicModules: undefined,
    publicModulesIsFetching: false,
    publicModulesHasError: false,
};

export const getStoreModulesAsync = createAsyncThunk(
    'module/getStoreModules',
    async (companyIdentifier: string) => {
        
        const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
        try {
            const response = await Axios.get<FoundationModule[]>(`${serverUrl}api/module/live/${companyIdentifier}`);
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const getPublicStoreModulesAsync = createAsyncThunk(
    'module/getPublicStoreModulesAsync',
    async()=> {
        const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
        try {
            const response = await Axios.get<FoundationModule[]>(`${serverUrl}api/module/public`);
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
)


export const modulesSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {},
    // pending
    // fulfilled
    // rejected
    extraReducers: (builder) => {
        builder
            .addCase(getStoreModulesAsync.pending, (state) => {
                state.modulesHasError = false;
                state.modulesIsFetching = true;
            })
            .addCase(getStoreModulesAsync.fulfilled, (state, action) => {
                state.modulesIsFetching = false;
                state.modules = action.payload;
            })
            .addCase(getStoreModulesAsync.rejected, (state) => {
                state.modulesIsFetching = false;
                state.modulesHasError = true;
            })
            .addCase(getPublicStoreModulesAsync.pending, (state) => {
                state.publicModulesHasError = false;
                state.publicModulesIsFetching = true;
            })
            .addCase(getPublicStoreModulesAsync.fulfilled, (state, action) => {
                state.publicModulesIsFetching = false;
                state.publicModules = action.payload;
            })
            .addCase(getPublicStoreModulesAsync.rejected, (state) => {
                state.publicModulesIsFetching = false;
                state.publicModulesHasError = true;
            })
 
    },
});


export const selectModules = (state: RootState) => state.module.modules;
export const selectModulesIsFetching = (state: RootState) => state.module.modulesIsFetching;
export const selectModulesError = (state: RootState) => state.module.modulesHasError;

export const selectPublicModules = (state: RootState) => state.module.publicModules;
export const selectPublicModulesIsFetching = (state: RootState) => state.module.publicModulesIsFetching;
export const selectPublicModulesError = (state: RootState) => state.module.publicModulesHasError;

export default modulesSlice.reducer;