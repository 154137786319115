import './log-ticket-modal.scss';

import Axios, { AxiosResponse } from "axios";
import { errorToast, successToast } from "../../utils/toast-utils";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Ticket } from "../../models/ticket";
import { addTicket } from "../../slices/tickets/ticket-slice";
import { getFormValue } from "../../utils/form-utils";
import { useAppDispatch } from "../../app/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export function LogTicketModal() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { user } = useAuth0();
    const { getAccessTokenSilently } = useAuth0();

    // const ticketTypes = useAppSelector(selectTicketTypes);
    // const ticketTypesError = useAppSelector(selectTicketTypesHasError);
    // const ticketTypesIsFetching = useAppSelector(selectTicketTypesIsFetching);
    const dispatch = useAppDispatch();

    // if (!ticketTypesIsFetching && ticketTypes === undefined && !ticketTypesError) {
    //     dispatch(getTicketTypesAsync());
    // }

    const nowDate = new Date()
    const now = nowDate.toISOString().split('T')[0];

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setValidated(false)
        setShow(true)
    };

    const handleFileSelected = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
      };

    const ticketTypes = [{name: 'Bug'}, {name:'Support'}]

    const handleSubmit = async (event) => {
        
        setLoading(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }
        setValidated(true);
        event.preventDefault();

        const type = getFormValue(form, 'formType');
        //const status = getFormValue(form, 'formStatus');
        const email = getFormValue(form, 'formEmail');
        const summary = getFormValue(form, 'formSummary');
        const description = getFormValue(form, 'formDescription');
        if (!user) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const companyId = user["https://foundation.com/app_metadata"]["root_company_identifier"]

        const url = window.location.href;

        let projectKey = 'SPCU';
        if(user){
            projectKey = 'SPCS'
        }

        const formData = new FormData();
        

        const postModel = {
            "type": type,
            //"createdDate": nowDate,
            //"status": status,
            "email": email,
            "summary": summary,
            "description": description + "    URL: " + url,
            "projectKey": projectKey,
            //"companyId": companyId
        };

        formData.append('type', type);
        formData.append('email', email);
        formData.append('summary', summary);
        formData.append('description', description);
        formData.append('projectKey', projectKey);
        if(selectedFile !== null){
        formData.append('file', selectedFile);
        }

        try {
            const accessToken = await getAccessTokenSilently();
            const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/ticket/jiraTicket`
            //const postUrl = `https://localhost:44378/api/ticket/jiraTicket`
            var res = await Axios.post<any, AxiosResponse<Ticket>>(postUrl, formData, { headers: { Authorization: `Bearer ${accessToken}`, } });
            dispatch(addTicket(res.data))
            successToast(`Ticket successfully logged`)
            handleClose();
        } catch (error: any) {
            errorToast(error.res.data)
        } finally {
            setLoading(false);
        }
    }

    return (
        <>

            {user && user.email_verified ? (
                <>
                    <Button variant="outline-light" className="wide log-ticket-button" onClick={handleShow}>
                        Log Ticket
                    </Button>
                    <Modal show={show} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
                        <Modal.Header>
                            <Modal.Title>Log a Ticket</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="w-100 h-100">
                                    <Col>

                                        <Form.Group className="mb-3" controlId="formType">
                                            <Form.Label>Ticket Type</Form.Label>

                                            <Form.Select>
                                                {ticketTypes.filter(item => item).map(item => (
                                                    <option>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                        {/* <Form.Group className="mb-3" controlId="formDate">
                                            <Form.Label>Created Date</Form.Label>
                                            <Form.Control type="date" required defaultValue={now} readOnly />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formStatus">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control required defaultValue="New" readOnly />
                                        </Form.Group> */}
                                        <Form.Group className="mb-3" controlId="formEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control required defaultValue={user.name} readOnly />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formSummary">
                                            <Form.Label>Summary</Form.Label>
                                            <Form.Control as="textarea" required rows={2} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formDescription">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control as="textarea" required rows={4} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formFile">
                                            <Form.Label>Select File</Form.Label>
                                             <Form.Control type="file" onChange={handleFileSelected} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Col md={{ span: 4, offset: 4 }}>
                                        <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col md={4}>
                                        <Button type="submit" className="w-100" disabled={isLoading}>
                                            {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Create</span>}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </>
            ) : <>{
                
                    <Spinner animation="border" variant="light" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    
            }</>
            }
        </>
    );
}
