import "./store-line-card.scss"

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { CategoryButton } from "../../../../components/category-button/category-button";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FoundationModule } from "../../../../models/module";
import { MoreDetailsPane } from "../more-details-pane/more-details-pane";
import Row from "react-bootstrap/Row";
import { useAuth0 } from "@auth0/auth0-react";
import { useContentful } from 'react-contentful';
import { useState } from "react";

export function StoreLineCard(props) {

    const moduleData: FoundationModule = props.data;
    const { user } = useAuth0();
    const [expanded, setExpanded] = useState(false);
    const handleExpandedChange = () => {
        setExpanded(!expanded)
    };
    const openSolution = () => {
        let qaulifiedAccessUrl = moduleData.accessUrl;
        if (qaulifiedAccessUrl && !qaulifiedAccessUrl.includes("http")) {
            qaulifiedAccessUrl = "https://" + qaulifiedAccessUrl;
        }
        window.open(qaulifiedAccessUrl, "_blank")
    }
    let moduleLogoUrl: string = ''
    let moduleMediaUrls: string[] = []
    const { data, error, fetched, loading } = useContentful({
        contentType: 'storeModule',
        //query: {
        //    'fields.uniqueIdentifier': moduleData.uniqueIdentifier.toUpperCase(),
        //}
    });

    function findImageUrl(items, identifier) : string {
        // Search through the items array
        const matchingItem = items.find(item => item.fields.uniqueIdentifier.toUpperCase() === identifier.toUpperCase());
      
        // If a matching item is found, return the URL
        return matchingItem ? matchingItem.fields.cardImage.fields.file.url : null;
    }

    //moduleLogoUrl = findImageUrl((data as any).items, moduleData.uniqueIdentifier);

    if (fetched && data && moduleLogoUrl === '' && !loading && !error) {
        let url = findImageUrl((data as any).items, moduleData.uniqueIdentifier);
        if (url != null)
        {
            //moduleLogoUrl = `https:${(data as any).items[0].fields.cardImage.fields.file.url}`;
            moduleLogoUrl = `https:${url}`;
        }
        else {
            moduleLogoUrl = '/Maluti_X.svg'
        }
        
        if ((data as any).items.length > 0 && (data as any).items[0].fields.moduleMedia?.length > 0) {
            for (let mediaItem of (data as any).items[0].fields.moduleMedia) {
                moduleMediaUrls.push(`https:${mediaItem.fields.file.url}`);
            }
        }
    }

    return (
        <>
            <Card className={"store-card " + (expanded ? "expanded" : "")}>
                <Card.Body style={{paddingLeft: 0}}>
                    <Row >
                        <Col md={1} className="module-image-col">
                            <img
                                className="module-image"
                                alt={moduleData.name}
                                src={moduleLogoUrl} />
                        </Col>
                        <Col md={11} className='left-border-col'>
                            <Row className="module-name-row" >
                                <Col md={8} onClick={handleExpandedChange}>{moduleData.name} &nbsp; <FontAwesomeIcon icon="chevron-down" className={"icon expand-toggle " + (expanded ? "expanded" : "")} onClick={handleExpandedChange} /></Col>
                                <Col md={4}>
                                    <MoreDetailsPane data={moduleData} moduleMediaUrls={moduleMediaUrls}></MoreDetailsPane>
                                </Col>
                            </Row>
                            <Row className={"expandable-row " + (expanded ? "expanded" : "")}>
                                <Row>
                                    <Col md={10} className={"description-col " + (expanded ? "expanded" : "")}>
                                        {moduleData.description}
                                    </Col>
                                </Row>
                            </Row>
                            <Row className="bottom-row">
                                <Col md={2} className="created-by">
                                    Created by <strong>{moduleData.author}</strong>
                                </Col>
                                <Col md={7} className="categories">
                                    {moduleData.categories.map(category => (
                                        <CategoryButton data={category}></CategoryButton>
                                    ))}
                                </Col>
                                <Col md={2}>
                                    {user && user.email_verified ? <Button variant="outline-secondary" className="open-solution-button float-right" onClick={openSolution} ><FontAwesomeIcon icon="external-link-alt" className="icon"/> Open solution</Button> : <></>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}

