import "./add-module-modal.scss"

import Axios, { AxiosResponse } from "axios";
import { FoundationModule, FoundationModuleCategory } from "../../../../models/module";
import { addModule, getModuleCategoriesAsync, selectApexModuleCategories, selectApexModulesCategoriesError, selectApexModulesCategoriesIsFetching } from "../../../../slices/apex-data/apex-modules-slice";
import { errorToast, successToast } from "../../../../utils/toast-utils";
import { getAllCompaniesAsync, selectCompanies, selectCompaniesError, selectCompaniesIsFetching } from "../../../../slices/apex-data/apex-data-slice";
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import Button from "react-bootstrap/Button";
import { CategoryButton } from "../../../../components/category-button/category-button";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner';
import { getFormValue } from "../../../../utils/form-utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export function AddModuleModal() {
  const companies = useAppSelector(selectCompanies);
  const isFetching = useAppSelector(selectCompaniesIsFetching);
  const error = useAppSelector(selectCompaniesError);
  const dispatch = useAppDispatch();

  const categories = useAppSelector(selectApexModuleCategories);
  const categoriesIsFetching = useAppSelector(selectApexModulesCategoriesIsFetching);
  const categoriesError = useAppSelector(selectApexModulesCategoriesError);


  if (!isFetching && companies === undefined && !error) {
    dispatch(getAllCompaniesAsync());
  }

  if (!categoriesIsFetching && categories === undefined && !categoriesError) {
    dispatch(getModuleCategoriesAsync());
  }

  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setValidated(false)
    setShow(true)
  };

  const [publicFlag, setPublicFlag] = useState(true);
  const handlePublicFlagChange = (event) => {
    setPublicFlag(event.target.checked)
  };

  //TODO: Might not be needed
  // const [selectedCompany, setSelectedCompany] = useState(undefined);
  // const [selectedCompanyName, setSelectedCompanyName] = useState(undefined);
  // const handleCompanyChange = (event) => {
  //   const options = event.target.options;
  //   const selectedIndex = options['selectedIndex']
  //   setSelectedCompany(options[selectedIndex].value)
  //   setSelectedCompanyName(options[selectedIndex].text)
  // };

  const initialCategoriesSelected = {}
  if (categories) {
    for (let category of categories) {
      initialCategoriesSelected[category.uniqueIdentifier] = false
    }
  }

  const [selectedCategories, setSelectedCategories] = useState(initialCategoriesSelected);

  const handleSelectCategory = (category: FoundationModuleCategory) => {
    const currentSelectedCategories = JSON.parse(JSON.stringify(selectedCategories))
    currentSelectedCategories[category.uniqueIdentifier] = !currentSelectedCategories[category.uniqueIdentifier];
    setSelectedCategories(currentSelectedCategories)
  }

  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }

    const name = getFormValue(form, 'formName');
    const url = getFormValue(form, 'formUrl');
    const author = getFormValue(form, 'formAuthor');
    const description = getFormValue(form, 'formDescription');
    const objectiveDescription = getFormValue(form, 'formObjectiveDescription');
    const inputDescription = getFormValue(form, 'formInputDescription');
    const outputDescription = getFormValue(form, 'formOutputDescription');
    const selectedCategoriesList: FoundationModuleCategory[] = []

    if (categories)
      for (let category of categories) {
        if (selectedCategories[category.uniqueIdentifier]) {
          selectedCategoriesList.push(category)
        }
      }

    const postModel = {
      name: name,
      accessUrl: url,
      author: author,
      description: description,
      objectiveDescription: objectiveDescription,
      inputDescription: inputDescription,
      outputDescription: outputDescription,
      public: publicFlag,
      categories: selectedCategoriesList
    } as FoundationModule;
    setValidated(true);
    event.preventDefault();

    try {
      const accessToken = await getAccessTokenSilently();
      const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
      const postUrl = `${serverUrl}api/module`;
      var res = await Axios.post<any, AxiosResponse<FoundationModule>>(postUrl, postModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
      dispatch(addModule(res.data))
      successToast(`Module ${name} created successfully`)
      handleClose();
    } catch (error: any) {
      errorToast(error.response.data)
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="primary" className="m-auto create-module-button" onClick={handleShow}>
        <FontAwesomeIcon icon="plus" className="icon" /> Create Module
      </Button>
      <Modal show={show} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
        <Modal.Header id="create-module-header">
          <Modal.Title>Create Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="w-100 h-100">
              <Col>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Module Name*</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">Please provide a name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formUrl">
                  <Form.Label>Module URL*</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">Please provide a URL.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAuthor">
                  <Form.Label>Module Author*</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">Please provide an author.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>Module Description*</Form.Label>
                  <Form.Control as="textarea" rows={4} required />
                  <Form.Control.Feedback type="invalid">Please provide a module description.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formObjectiveDescription">
                  <Form.Label>Description of module objectives*</Form.Label>
                  <Form.Control as="textarea" rows={4} required />
                  <Form.Control.Feedback type="invalid">Please provide a description of the module objectives.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formInputDescription">
                  <Form.Label>Description of possible inputs*</Form.Label>
                  <Form.Control as="textarea" rows={4} required />
                  <Form.Control.Feedback type="invalid">Please provide a description of the possible inputs.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOutputDescription">
                  <Form.Label>Description of possible outputs*</Form.Label>
                  <Form.Control as="textarea" rows={4} required />
                  <Form.Control.Feedback type="invalid">Please provide a description of the possible outputs.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCategories">
                  <Form.Label>Categories*</Form.Label>
                  {!categoriesIsFetching && categories !== undefined ? (
                    <>
                      {categories.map(category => (
                        <>
                          <Row onClick={() => handleSelectCategory(category)} >
                            <Col md={5} className='category-col'>
                              <CategoryButton data={category} ></CategoryButton>
                            </Col>
                            <Col md={{ span: 1, offset: 5 }}>
                              {selectedCategories[category.uniqueIdentifier] && <FontAwesomeIcon icon="check" className="icon" />}
                            </Col>
                          </Row>
                          <br />
                        </>
                      ))}
                    </>
                  ) : (
                    <Spinner animation="border" variant="dark" role="status">
                      <   span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </Form.Group>
                <Row className="w-100">
                  <Col>
                    <Form.Group className="mb-3" controlId="formPublicFlag">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Public module"
                        defaultChecked={publicFlag}
                        onChange={handlePublicFlagChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="w-100">
              <Col md={{ span: 4, offset: 4 }}>
                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading}>
                  Cancel
                </Button>
              </Col>
              <Col md={4}>
                <Button type="submit" className="w-100" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Confirm</span>}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

