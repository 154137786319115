import './App.scss';

import { ContentfulClient, ContentfulProvider } from 'react-contentful';
import { Route, Switch } from 'react-router-dom';
import { faBook, faBuilding, faCheck, faChevronDown, faClone, faCogs, faEdit, faExternalLinkAlt, faHome, faPlus, faStore, faTrashAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'

import { ApexToolsPage } from './pages/apex-tools/apex-tools';
import { Blog } from './pages/blog/blog';
import { BlogTest } from './pages/blog-test/blog-test';
import { CreateCompanyPage } from './pages/create-company/create-company-page';
import ErrorBoundary from './components/error-boundary/error-boundary';
import { GetDarkTheme } from './utils/auth0-metadata-utils'
import { HomePage } from './pages/home-page/home-page';
import { LandingPage } from './pages/landing-page/landing-page';
import { Loading } from './components/loading/loading';
import { MyCompanyPage } from './pages/my-company/my-company';
import { ProfilePage } from './pages/profile/profile';
import ProtectedRoute from './components/auth/protected-route';
import { ResolutionUnsupportedPage } from './pages/resolution-unsupported-page/resolution-unsupported-page';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Sidebar from './components/sidebar/sidebar';
import { StoreFrontPage } from './pages/store-front/store-front';
import { ToastContainer } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core'
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from 'react-responsive';

library.add(faStore, faBuilding, faCogs, faUserCircle, faHome, faPlus, faChevronDown, faEdit, faClone, faTrashAlt, faExternalLinkAlt, faCheck, faBook)

const contentfulConfig = {
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
}
const contentfulClient = new (ContentfulClient as any)(contentfulConfig);

function App() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const body = document.body;
  let theme: string;
  let lightTheme: string = 'light';
  let darkTheme: string = 'dark';

  if (user) {
    theme = [GetDarkTheme() ? lightTheme : darkTheme].toString();
    if (theme === lightTheme) {
      localStorage.setItem("theme", "light");
      body.classList.add('light');
    }
    else if (theme === darkTheme) {
      localStorage.setItem("theme", "dark");
      body.classList.add('dark');
    }
  }else{
    localStorage.setItem("theme", "light");
    body.classList.add('light');
  }

  const isMobile = useMediaQuery({ query: `(max-width: 750px)` });

  return (
    <div className="App">
      <ContentfulProvider client={contentfulClient}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
        <Scrollbars>
          <ErrorBoundary>
            {isLoading ? <Loading /> : <></>}
            {isMobile ? <ResolutionUnsupportedPage /> : <></>}
            <Sidebar />
            <div className="main-viewport">
              <Switch>

                {
                  isAuthenticated && !isLoading && user ?
                    [
                      user["https://foundation.com/app_metadata"] && user["https://foundation.com/app_metadata"]["root_company_identifier"] ?
                        <ProtectedRoute exact={true} path="/" component={HomePage} /> :
                        // <ProtectedRoute exact={true} path="/" component={LandingPage} /> :
                        <ProtectedRoute path="/" component={CreateCompanyPage} />
                    ] :
                    <Route exact={true} path="/" component={HomePage} />
                }
                <Route path="/home" component={HomePage} />
                {/* <Route path="/blog" component={BlogTest} /> */}
                <Route path="/landing" component={LandingPage} />
                <ProtectedRoute path="/companies" component={MyCompanyPage} />
                <Route exact={true} path="/store" component={StoreFrontPage} />
                <ProtectedRoute path="/apex" component={ApexToolsPage} />
                <ProtectedRoute path="/profile" component={ProfilePage} />
                <ProtectedRoute path="/create" component={CreateCompanyPage} />

              </Switch>
            </div>
          </ErrorBoundary>
        </Scrollbars>
      </ContentfulProvider>
    </div>
  );
}

export default App;