import "./invite-new-user-modal.scss";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { selectCompany, addUserInvite } from "../../../../slices/company/company-slice";
import { errorToast, successToast } from "../../../../utils/toast-utils";
import Axios, { AxiosResponse } from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { getFormValue } from "../../../../utils/form-utils";
import { CompanyInvite } from "../../../../models/company";

export function InviteNewUserModal() {
    const dispatch = useAppDispatch();
    const company = useAppSelector(selectCompany);

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setValidated(false)
        setShow(true)
    };

    const [validated, setValidated] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }
        if (company) {
            const inviteEmail:string = getFormValue(form, 'formEmail');
            const inviteEmailLower = inviteEmail.toLowerCase();
            setValidated(true);
            event.preventDefault();

            try {
                const accessToken = await getAccessTokenSilently();
                const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/company/${company.uniqueIdentifier}/invite?userEmail=${inviteEmailLower}`
                const res = await Axios.post<any, AxiosResponse<CompanyInvite>>(postUrl, null, { headers: { Authorization: `Bearer ${accessToken}`, } });
                successToast(`Invite to ${inviteEmail} sent successfully.`);
                dispatch(addUserInvite(res.data))
                handleClose();
            } catch (error: any) {
                errorToast(error.response.data)
            } finally {
                setLoading(false);
            }
        } else {
            errorToast("No company data available.")
            setLoading(false);
        }
    };

    return (
        <>
            <Button variant="primary" className="float-end" onClick={handleShow}>
                Invite New User
            </Button>
            <Modal show={show} onHide={handleClose} backdrop='static' animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
                <Modal.Header id="invite-new-user-header">
                    <Modal.Title>Invite a New User to {company?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="w-100 h-100">
                            <Col>
                                <h4>Company details</h4>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>User email *</Form.Label>
                                    <Form.Control type="email" required />
                                    <Form.Control.Feedback type="invalid">Please provide a user email.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="w-100">
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col md={4}>
                                <Button type="submit" className="w-100" disabled={isLoading}>
                                    {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Confirm</span>}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
