import "./users.scss"
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectCompany, selectCompanyError, selectCompanyIsFetching, selectCompanyInvitesError, selectCompanyInvitesIsFetching, getCompanyInvitesAsync } from "../../../slices/company/company-slice";
import Container from "react-bootstrap/Container"
import Table from "react-bootstrap/Table"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Placeholder from "react-bootstrap/Placeholder"
import { InviteNewUserModal } from "./invite-new-user-modal/invite-new-user-modal";
import { CompanyInvite } from "../../../models/company";
import moment from "moment";

export function UsersView() {
    const company = useAppSelector(selectCompany);
    const companyIsFetching = useAppSelector(selectCompanyIsFetching);
    const companyHasError = useAppSelector(selectCompanyError);

    const invitesIsFetching = useAppSelector(selectCompanyInvitesError);
    const invitesHasError = useAppSelector(selectCompanyInvitesIsFetching);
    const dispatch = useAppDispatch();

    if (!companyIsFetching && company !== undefined && !companyHasError && company.invites === undefined && !invitesIsFetching && !invitesHasError) {
        dispatch(getCompanyInvitesAsync(company.uniqueIdentifier));
    }

    return (
        <Container fluid >

            <br />
            {!companyIsFetching && company !== undefined ?
                <>
                    <Row>
                        <Col>
                            <h2>{company.name} ({company.taxNumber})</h2>
                        </Col>
                        <Col>
                            <InviteNewUserModal></InviteNewUserModal>
                        </Col>
                    </Row>
                    <hr />
                    {
                        company.users.length === 0 ? <h3>No users</h3> :
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Last login
                                        </th>
                                        <th>
                                            Number of logins
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {company.users.map(user => (
                                        <tr>
                                            <td>{user.email}</td>
                                            <td>{user.lastLogin === undefined || user.lastLogin === null ? "Never" : moment(user.lastLogin).toLocaleString()}</td>
                                            <td>{user.numberOfLogins}</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                    }
                    <hr />
                    <h3>Invites</h3>
                    {
                        (!invitesIsFetching && company !== undefined && company.invites !== undefined && company.invites.length === 0) ? <h6>No invites</h6> :
                            <>
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>
                                                New User
                                            </th>
                                            <th>
                                                Invited By
                                            </th>
                                            <th>
                                                Code
                                            </th>
                                            <th>
                                                Accepted
                                            </th>
                                            <th>
                                                Expired
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {company?.invites?.map((invite: CompanyInvite) => (
                                            <tr>
                                                <td>{invite.inviteeEmail}</td>
                                                <td> {invite.invitorEmail} </td>
                                                <td> {invite.inviteCode} </td>
                                                <td> {invite.completed ? "✔" : "pending..."} </td>
                                                {/* <td> {invite.completed ? "✔" : "❌"} </td> */}
                                                {/* <td> {invite.expired ? "✔" : "❌"} </td> */}
                                                <td> {invite.expired ? "✔" : "pending..."} </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </Table>
                            </>
                    }
                </>
                :
                <>
                    <Row>

                        <Col>
                            <Placeholder as="h2" animation="glow">
                                <Placeholder xs={6} size="lg" />
                            </Placeholder>
                        </Col>
                        <Col>
                            <Placeholder.Button variant="primary" xs={2} size="lg" className="float-end" animation="glow" />
                        </Col>
                    </Row>
                    <hr />
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Last login
                                </th>
                                <th>
                                    Number of logins
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <hr />
                    <h3>Invites</h3>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>
                                    New User
                                </th>
                                <th>
                                    Invited By
                                </th>
                                <th>
                                    Code
                                </th>
                                <th>
                                    Accepted
                                </th>
                                <th>
                                    Expired
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                                <td>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={2} size="lg" />
                                    </Placeholder>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </>

            }
        </Container>
    )
}
