import "./error-boundary.scss";
import { Component } from "react";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    
    return { hasError: true };
  }

  clearError(cls) {
    cls.state = { hasError: false }; 
    // const history = useHistory();
    window.location.href="\\";
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    if ((this.state as any).hasError) {
      // You can render any custom fallback UI
      return (<Container fluid className="h-100 error-container">
        <h1>Something went seriously wrong</h1>
        <br />
        <h3>Please contact info@maluti-x.com for futher assistance</h3>
        <hr />
        <Link variant="primary" className="btn btn-primary return-button" onClick={this.clearError}>
          <span className="nav-text">Return to home page </span>
        </Link>
      </Container>);
    }
    return this.props.children;
  }
}