import "./create-sub-company-modal.scss";
import { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { selectCompany, addSubCompany } from "../../../../slices/company/company-slice";
import { errorToast, successToast } from "../../../../utils/toast-utils";
import Axios, { AxiosResponse } from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Address, Company } from "../../../../models/company";
import { getFormValue } from "../../../../utils/form-utils";
import countryList from 'react-select-country-list';

export function CreateSubCompanyModal() {
    const dispatch = useAppDispatch();

    const company = useAppSelector(selectCompany);
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setValidated(false)
        setShow(true)
    };

    const [validated, setValidated] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const [country, setCountry] = useState(company?.address.country ?? 'South Africa')
    const countryOptions = useMemo(() => countryList().getData(), [])
    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const name = getFormValue(form, 'formName');
        const currency = getFormValue(form, 'formCurrency');
        const taxNumber = getFormValue(form, 'formTaxNumber');
        const addressStreet = getFormValue(form, 'formStreetAddress');
        const addressCity = getFormValue(form, 'formCity');
        const addressCountry = country;
        const addressPostalCode = getFormValue(form, 'formPostalCode');

        if (company) {
            const postModel = {
                name: name,
                currency: currency,
                taxNumber: taxNumber,
                address: {
                    street: addressStreet,
                    city: addressCity,
                    country: addressCountry,
                    postalCode: addressPostalCode
                } as Address,
                parentCompanyIdentifier: company.uniqueIdentifier
            };

            setValidated(true);
            event.preventDefault();

            try {
                const accessToken = await getAccessTokenSilently();
                const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/company/sub`
                var res = await Axios.post<any, AxiosResponse<Company>>(postUrl, postModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
                dispatch(addSubCompany(res.data));
                successToast("Sub-company created successfully")
                handleClose();
            } catch (error: any) {
                errorToast(error.response.data)
            } finally {
                setLoading(false);
            }
        } else {
            errorToast("No company data available.")
            setLoading(false);
        }
    };

    return (
        <>
            <Button variant="primary" className="float-end" onClick={handleShow}>
                Create Sub-Company
            </Button>
            <Modal show={show} onHide={handleClose} backdrop='static' animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
                <Modal.Header id="create-sub-company-header">
                    <Modal.Title>Add a New Sub-Company for {company?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-hidden">
                    <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="w-100 h-100">
                            <Col>
                                <h4>Company details</h4>
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Name *</Form.Label>
                                    <Form.Control type="text" required />
                                    <Form.Control.Feedback type="invalid">Please provide a company name.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCurrency">
                                    <Form.Label>Currency *</Form.Label>
                                    <Form.Select>
                                        <option>ZAR</option>
                                        <option>USD</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formTaxNumber">
                                    <Form.Label>Tax Number *</Form.Label>
                                    <Form.Control type="text" required />
                                    <Form.Control.Feedback type="invalid">Please provide a tax number.</Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        Please note this will appear on any future invoices.
                                    </Form.Text>
                                </Form.Group>
                                <h4>Address</h4>
                                <Row className="w-100">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formStreetAddress">
                                            <Form.Label>Street Name and Number *</Form.Label>
                                            <Form.Control type="text" required/>
                                            <Form.Control.Feedback type="invalid">Please provide a street name and number.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formCity">
                                            <Form.Label>City/Town *</Form.Label>
                                            <Form.Control type="text" required/>
                                            <Form.Control.Feedback type="invalid">Please provide a city or town.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formCountry">
                                            <Form.Label>Country *</Form.Label>
                                            <Form.Select value={country} onChange={handleChangeCountry}>
                                                {countryOptions.map(country => (<option>{country.label}</option>))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">Please provide a country.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPostalCode">
                                            <Form.Label>Postal Code *</Form.Label>
                                            <Form.Control type="text" required className="postal-code"/>
                                            <Form.Control.Feedback type="invalid">Please provide a postal code.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="w-100">
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col md={4}>
                                <Button type="submit" className="w-100" disabled={isLoading}>
                                    {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Confirm</span>}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
