import "./overview.scss"

import { getCompanyAsync, selectCompany, selectCompanyError, selectCompanyIsFetching } from "../../../slices/company/company-slice";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { CreateSubCompanyModal } from "./create-sub-company-modal/create-sub-company-modal";
import { EditCompanyModal } from "./edit-company-modal/edit-company-modal";
import Placeholder from "react-bootstrap/Placeholder"
import Row from "react-bootstrap/Row"
import Table from "react-bootstrap/Table"

export function CompanyOverviewView() {
    const company = useAppSelector(selectCompany);
    const isFetching = useAppSelector(selectCompanyIsFetching);
    const error = useAppSelector(selectCompanyError);
    const dispatch = useAppDispatch();

    if (!isFetching && company === undefined && !error) {
        dispatch(getCompanyAsync());
    }

    return (
        <Container fluid >
            <br />
            {!isFetching && company !== undefined && company.address !== undefined ?
                <>
                    <Row>
                        <Col>
                            <h2>{company.name} ({company.taxNumber}) <EditCompanyModal data={company}></EditCompanyModal> </h2>
                        </Col>
                        <Col> <CreateSubCompanyModal></CreateSubCompanyModal></Col>
                    </Row>
                    <hr />
                    <Row><h3>Address</h3></Row>
                    <Row><span>Street name and number: {company.address.street}</span></Row>
                    <Row><span>City: {company.address.city}</span></Row>
                    <Row><span>Country: {company.address.country}</span></Row>
                    <Row><span>Postal code: {company.address.postalCode}</span></Row>
                    {
                        company.children.length === 0 ? <h6>No sub companies</h6> :
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>
                                            Sub-Company Name
                                        </th>
                                        <th>
                                            Active
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {company.children.map(sub => (
                                        <tr>
                                            <td>{sub.name}</td>
                                            <td>{sub.enabled ? "✔" : "❌"} </td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                    }
                </>
                :
                <>
                    <Row>

                        <Col>
                            <Placeholder as="h2" animation="glow">
                                <Placeholder xs={6} size="lg" />
                            </Placeholder>
                        </Col>
                        <Col>
                            <Placeholder.Button variant="primary" xs={2} size="lg" className="float-end" animation="glow" />
                        </Col>
                    </Row>
                    <hr />
                    <Row><h3>Address</h3></Row>
                    <Row>
                        <Placeholder as="span" animation="glow">
                            <span>Street name and number: <Placeholder xs={1} /></span>
                        </Placeholder>
                    </Row>
                    <Row>
                        <Placeholder as="span" animation="glow">
                            <span>City: <Placeholder xs={1} /></span>
                        </Placeholder>
                    </Row>
                    <Row>
                        <Placeholder as="span" animation="glow">
                            <span>Country: <Placeholder xs={1} /></span>
                        </Placeholder>
                    </Row>
                    <Row>
                        <Placeholder as="span" animation="glow">
                            <span>Postal code: <Placeholder xs={1} /></span>
                        </Placeholder>
                    </Row>

                </>
            }
        </Container>
    );
}