import "./sidebar.scss"

import { useEffect, useState } from "react";

import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { LogTicketModal } from "../log-ticket/log-ticket-modal";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner'
import { useAuth0 } from "@auth0/auth0-react";

const SideBar = () => {


    const { user, isLoading, loginWithRedirect, logout } = useAuth0();
    const roles: string[] = user && user["https://foundation.com/roles"] ? user["https://foundation.com/roles"] : []


    return (
        <>
            <Navbar className="sidebar">
                <Navbar.Brand href="/" className="ml-auto mr-auto">
                    <Link variant="outline-light" to={{ pathname: "/home", }}>
                    {/* <Link variant="outline-light" to={user ? { pathname: "/landing", } : { pathname: "/home", }}> */}
                        <img
                            alt=""
                            src="/Maluti_X.svg"
                            className="d-inline-block align-top logo"
                        />{' '}
                    </Link>
                </Navbar.Brand>

                <Nav className="me-auto">
                    <Link variant="outline-light" to={{ pathname: "/home", }} className="btn btn-outline-light">
                    {/* <Link variant="outline-light" to={user ? { pathname: "/landing", } : { pathname: "/home", }} className="btn btn-outline-light"> */}
                        <FontAwesomeIcon icon="home" className="icon" />
                        <span className="nav-text">Home </span>
                    </Link>
                    <Link variant="outline-light" to={{ pathname: "/store", }} className="btn btn-outline-light">
                        <FontAwesomeIcon icon="store" className="icon" />
                        <span className="nav-text">Solutions</span>
                    </Link>
                    
                    {/*Removing blog for the time being
                     <Link variant="outline-light" to={{ pathname: "/blog", }} className="btn btn-outline-light">
                        <FontAwesomeIcon icon="book" className="icon" />
                        <span className="nav-text">Blog</span>
                    </Link> */}

                    {roles.includes("Standard") || roles.includes("Admin") && user ?
                        <Link variant="outline-light" to={{ pathname: "/companies", }} className="btn btn-outline-light">
                            <FontAwesomeIcon icon="building" className="icon" />
                            <span className="nav-text">Companies</span>
                        </Link>
                        :
                        <></>
                    }
                    {roles.includes("Apex") && user ?
                        <Link variant="outline-light" to={{ pathname: "/apex", }} className="btn btn-outline-light">
                            <FontAwesomeIcon icon="cogs" className="icon" />
                            <span className="nav-text">Apex Tools</span>
                        </Link>
                        :
                        <></>
                    }
                </Nav>
                {isLoading ?
                    <Spinner animation="border" variant="light" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <>
                        {user ? <LogTicketModal /> : <></>}      
                        <Navbar.Text className="wide">
                            <div className="username">
                                {user ?
                                    <>
                                        <Link variant="outline-light" to={{ pathname: "/profile" }} className="btn btn-outline-light profile-button">{user.name?.charAt(0).toLocaleUpperCase()}</Link>
                                        <br />
                                        <span className="username-text">{user.name} </span>

                                        <br />
                                        <Button variant="outline-light login-logout-button" onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
                                    </>

                                    :
                                    <Button variant="outline-light login-logout-button" onClick={() => loginWithRedirect()}>Login/<wbr />Register</Button>
                                }
                            </div>
                        </Navbar.Text>
                    </>
                }

            </Navbar>


        </>
    );
}

export default SideBar;