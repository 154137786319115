import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApexDataState } from "./apex-data-state";
import { Article } from '../../models/article';
import Axios from "axios";
import { Company } from "../../models/company";
import { CompanyUser } from "../../models/user";
import { ContentModel } from '../../models/content-model';
import { PricingStructure } from "../../models/pricing-structure";
import { RootState } from '../../app/store';
import { errorToast } from "../../utils/toast-utils";
import { useAuth0 } from "@auth0/auth0-react";

const initialState: ApexDataState = {
    pricingStructures: undefined,
    pricingStructuresIsFetching: false,
    pricingStructuresHasError: false,
    companies: undefined,
    companiesIsFetching: false,
    companiesHasError: false,
    users: undefined,
    usersIsFetching: false,
    usersHasError: false,
    contentTypes: undefined,
    contentTypesIsFetching: false,
    contentTypesHasError: false,
    articlesApex: undefined,
    articlesApexIsFetching: false,
    articlesApexHasError: false
};

export const getPricingStructuresAsync = createAsyncThunk(
    'apexData/getPricingStructures',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<PricingStructure[]>(`${serverUrl}api/pricing`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const getAllCompaniesAsync = createAsyncThunk(
    'apexData/getAllCompanies',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<Company[]>(`${serverUrl}api/company`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);


export const getAllUsersAsync = createAsyncThunk(
    'users/getAllUsers',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<CompanyUser[]>(`${serverUrl}api/user`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const getContentTypes = createAsyncThunk(
    'apexData/getContentTypes',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        //TODO update url path
        const serverUrl = process.env.REACT_APP_BLOG_SERVER_URL;
        try {
            const response = await Axios.get<ContentModel[]>(`${serverUrl}api/blog/contentTypes`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
)

export const getArticlesApex = createAsyncThunk(
    'apexData/getArticles',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        //TODO update url path
        const serverUrl = process.env.REACT_APP_BLOG_SERVER_URL;
        try {
            const response = await Axios.get<Article[]>(`${serverUrl}api/blog/articles/apex`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
)

export const apexDataSlice = createSlice({
    name: 'apex-data',
    initialState,
    reducers: {
        addPricingStructure: (state, action: PayloadAction<PricingStructure>) => {
            // TODO: Check end time and determine currently active status
            state.pricingStructures?.push(action.payload);
        },
        removeUser: ( state, action: PayloadAction<string>)=>{
            let index = state.users?.findIndex(n => n.uniqueIdentifier === action.payload);
            if(index === undefined || index < 0){
                return;
            }
            state.users?.splice(index, 1);
        } 

    },
    // pending
    // fulfilled
    // rejected
    extraReducers: (builder) => {
        builder
            .addCase(getPricingStructuresAsync.pending, (state) => {
                state.pricingStructuresHasError = false;
                state.pricingStructuresIsFetching = true;
            })
            .addCase(getPricingStructuresAsync.fulfilled, (state, action) => {
                state.pricingStructuresIsFetching = false;
                state.pricingStructures = action.payload;
            })
            .addCase(getPricingStructuresAsync.rejected, (state, action) => {
                state.pricingStructuresIsFetching = false;
                state.pricingStructuresHasError = true;
            })
            .addCase(getAllCompaniesAsync.pending, (state) => {
                state.companiesHasError = false;
                state.companiesIsFetching = true;
            })
            .addCase(getAllCompaniesAsync.fulfilled, (state, action) => {
                state.companiesIsFetching = false;
                state.companies = action.payload;
            })
            .addCase(getAllCompaniesAsync.rejected, (state, action) => {
                state.companiesIsFetching = false;
                state.companiesHasError = true;
            })
            .addCase(getAllUsersAsync.pending, (state) => {
                state.usersHasError = false;
                state.usersIsFetching = true;
            })
            .addCase(getAllUsersAsync.fulfilled, (state, action) => {
                state.usersIsFetching = false;
                state.users = action.payload;
            })
            .addCase(getAllUsersAsync.rejected, (state, action) => {
                state.usersIsFetching = false;
                state.usersHasError = true;
            })
            .addCase(getContentTypes.pending, (state) => {
                state.contentTypesHasError = false;
                state.contentTypesIsFetching = true;
            })
            .addCase(getContentTypes.fulfilled, (state, action) => {
                state.contentTypesIsFetching = false;
                state.contentTypes = action.payload;
            })
            .addCase(getContentTypes.rejected, (state, action) =>{
                state.contentTypesIsFetching = false;
                state.contentTypesHasError = true
            })
            .addCase(getArticlesApex.pending, (state) => {
                state.articlesApexHasError = false;
                state.articlesApexIsFetching = true;
            })
            .addCase(getArticlesApex.fulfilled, (state, action) => {
                state.articlesApexIsFetching = false;
                state.articlesApex = action.payload;
            })
            .addCase(getArticlesApex.rejected, (state, action) =>{
                state.articlesApexIsFetching = false;
                state.articlesApexHasError = true
            });
    },
});

export const selectPricingStructures = (state: RootState) => state.apexData.pricingStructures;
export const selectPricingStructuresIsFetching = (state: RootState) => state.apexData.pricingStructuresIsFetching;
export const selectPricingStructuresError = (state: RootState) => { return state.apexData.pricingStructuresHasError };

export const selectCompanies = (state: RootState) => state.apexData.companies;
export const selectCompaniesIsFetching = (state: RootState) => state.apexData.companiesIsFetching;
export const selectCompaniesError = (state: RootState) => { return state.apexData.companiesHasError };

export const selectUsers = (state: RootState) => state.apexData.users;
export const selectUsersIsFetching = (state: RootState) => state.apexData.usersIsFetching;
export const selectUsersError = (state: RootState) => { return state.apexData.usersHasError };

export const selectContentTypes = ( state: RootState) => state.apexData.contentTypes;
export const selectContentTypesIsFetching = ( state : RootState) => state.apexData.contentTypesIsFetching;
export const selectContentTypesHasError = (state: RootState) => {return state.apexData.contentTypesHasError};

export const selectArticlesApex = ( state: RootState) => state.apexData.articlesApex;
export const selectArticlesApexIsFetching = ( state : RootState) => state.apexData.articlesApexIsFetching;
export const selectArticlesApexHasError = (state: RootState) => {return state.apexData.articlesApexHasError};

export const { addPricingStructure, removeUser } = apexDataSlice.actions;

export default apexDataSlice.reducer;