import { FoundationModule, FoundationModuleCategory } from "../../models/module";
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApexModulesState } from "./apex-modules-state";
import Axios from "axios";
import { ModuleCompanyRelationship } from "../../models/module-company-relationship";
import { RootState } from '../../app/store';
import { errorToast } from "../../utils/toast-utils";
import { useAuth0 } from "@auth0/auth0-react";

const initialState: ApexModulesState = {
    modules: undefined,
    modulesIsFetching: false,
    modulesHasError: false,
    moduleCategories: undefined,
    moduleCategoriesIsFetching: false,
    moduleCategoriesHasError: false,
    modulesCompanyRelationship: undefined,
    modulesCompanyRelationshipIsFetching: false,
    modulesCompanyRelationshipHasError: false,
};

export const getAllModulesAsync = createAsyncThunk(
    'apexData/getAllModules',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
        try {
            const response = await Axios.get<FoundationModule[]>(`${serverUrl}api/module`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const getAllModulesCompanyRelationshipAsync = createAsyncThunk(
    'apexData/getAllModulesCompanyRelationship',
    async (moduleIdentifier: string) => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
        try {
            const response = await Axios.get<ModuleCompanyRelationship[]>(`${serverUrl}api/module/modulesCompanyRelationship/${moduleIdentifier}`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const getModuleCategoriesAsync = createAsyncThunk(
    'module/getModuleCategories',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
        try {
            const response = await Axios.get<FoundationModuleCategory[]>(`${serverUrl}api/category`, { headers: { Authorization: `Bearer ${token}`}});
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);


export const apexModulesSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        addModule: (state, action: PayloadAction<FoundationModule>) => {
            state.modules?.push(action.payload);
        },
        removeModule: (state, action: PayloadAction<string>) => {
            let index = state.modules?.findIndex(m => m.uniqueIdentifier === action.payload);
            if (index === undefined || index < 0){
                return;
            } 
            state.modules?.splice(index, 1);
        },
        updateModule: (state, action: PayloadAction<FoundationModule>) => {
            let index = state.modules?.findIndex(m => m.uniqueIdentifier === action.payload.uniqueIdentifier);
            if (state.modules === undefined || index === undefined || index < 0){
                return;
            } 
            state.modules[index] = action.payload;
        },
        addModuleCategory: (state, action: PayloadAction<FoundationModuleCategory>) => {
            state.moduleCategories?.push(action.payload);
        },
        removeModuleCategory: (state, action: PayloadAction<string>) => {
            let index = state.moduleCategories?.findIndex(m => m.uniqueIdentifier === action.payload);
            if (index === undefined || index < 0){
                return;
            } 
            state.moduleCategories?.splice(index, 1);
        },
        addModuleCompanyRelationship: (state, action: PayloadAction<ModuleCompanyRelationship>) => {
            state.modulesCompanyRelationship?.push(action.payload);
        },
    },
    // pending
    // fulfilled
    // rejected
    extraReducers: (builder) => {
        builder
            .addCase(getAllModulesAsync.pending, (state) => {
                state.modulesHasError = false;
                state.modulesIsFetching = true;
            })
            .addCase(getAllModulesAsync.fulfilled, (state, action) => {
                state.modulesIsFetching = false;
                state.modules = action.payload;
            })
            .addCase(getAllModulesAsync.rejected, (state, action) => {
                state.modulesIsFetching = false;
                state.modulesHasError = true;
            })
            .addCase(getModuleCategoriesAsync.pending, (state) => {
                state.moduleCategoriesHasError = false;
                state.moduleCategoriesIsFetching = true;
            })
            .addCase(getModuleCategoriesAsync.fulfilled, (state, action) => {
                state.moduleCategoriesIsFetching = false;
                state.moduleCategories = action.payload;
                
            })
            .addCase(getModuleCategoriesAsync.rejected, (state, action) => {
                state.moduleCategoriesIsFetching = false;
                state.moduleCategoriesHasError = true;
            })
            .addCase(getAllModulesCompanyRelationshipAsync.pending, (state) => {
                state.modulesCompanyRelationshipHasError = false;
                state.modulesCompanyRelationshipIsFetching = true;
            })
            .addCase(getAllModulesCompanyRelationshipAsync.fulfilled, (state, action) => {
                state.modulesCompanyRelationshipIsFetching = false;
                state.modulesCompanyRelationship = action.payload;
            })
            .addCase(getAllModulesCompanyRelationshipAsync.rejected, (state, action) => {
                state.modulesCompanyRelationshipIsFetching = false;
                state.modulesCompanyRelationshipHasError = true;
            })
    },
});


export const selectApexModules = (state: RootState) => state.apexModule.modules;
export const selectApexModulesIsFetching = (state: RootState) => state.apexModule.modulesIsFetching;
export const selectApexModulesError = (state: RootState) => { return state.apexModule.modulesHasError };
export const selectApexModuleCategories = (state: RootState) => state.apexModule.moduleCategories;
export const selectApexModulesCategoriesIsFetching = (state: RootState) => state.apexModule.moduleCategoriesIsFetching;
export const selectApexModulesCategoriesError = (state: RootState) => state.apexModule.moduleCategoriesHasError;
export const selectApexModulesCompanyRelationship = (state: RootState) => state.apexModule.modulesCompanyRelationship;
export const selectApexModulesCompanyRelationshipIsFetching = (state: RootState) => state.apexModule.modulesCompanyRelationshipIsFetching;
export const selectApexModulesCompanyRelationshipError = (state: RootState) => { return state.apexModule.modulesCompanyRelationshipHasError };

export const { addModule, removeModule, updateModule, addModuleCategory, removeModuleCategory } = apexModulesSlice.actions;

export default apexModulesSlice.reducer;