import "./my-company.scss"

import { CompanyOverviewView } from "./overview/overview"
import { CompanyTicketsView } from "./company-tickets/company-tickets"
import Container from "react-bootstrap/Container"
import { CreditHistoryView } from "./credit-history/credit-history"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { UsersView } from "./users/users"
import { useAuth0 } from "@auth0/auth0-react"

export function MyCompanyPage() {
    const { user } = useAuth0();
    const roles: string[] = user && user["https://foundation.com/roles"] ? user["https://foundation.com/roles"] : []
    return (
        <Container fluid className="h-100 w-100 p-5">
            <h1>My Company</h1>
            <Tabs defaultActiveKey="overview" className="mb-3, centered-nav">
                <Tab eventKey="overview" title="Overview">
                    <CompanyOverviewView />
                </Tab>
                {roles.includes("Admin") && user ?
                    <Tab eventKey="users" title="Users">
                        <UsersView />
                    </Tab>
                    :
                    <></>
                }
                {/* {roles.includes("Admin") && user ?
                    <Tab eventKey="credithistory" title="Credit History">
                        <CreditHistoryView />
                    </Tab>
                    :
                    <></>
                } */}
                {/*<Tab eventKey="Tickets" title="Tickets">
                     <CompanyTicketsView /> 
                </Tab>*/}
            </Tabs>
        </Container>
    )
}



