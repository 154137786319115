import "./apex-tools.scss"

import { BlogManagement } from "./blog/blog"
import Col from 'react-bootstrap/Col'
import Container from "react-bootstrap/Container"
import { ModulesView } from "./modules/modules"
import Nav from 'react-bootstrap/Nav'
import { PaymentsView } from "./payments/payments"
import { PricingStructureView } from "./pricing-structure/pricing-structure"
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import { TicketsView } from "./tickets/tickets"
import { UsersView } from "./userManagement/user-management"

export function ApexToolsPage() {
    return (
        <Container fluid className="h-100 w-100 p-5">
            <h1 className="apex-tools-heading">Apex Tools</h1>
            <Container fluid className="apex-tools-custom-tabs-container h-100 w-100">
                <Tab.Container defaultActiveKey="modules">
                    <Row>
                        <Col sm={3} className="apex-tools-custom-tabs">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="modules"><img src="/assets/module-details-pane/SquaresFourWhite.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Solutions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="pricing-structure"><img src="/assets/module-details-pane/Coin.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Pricing Structure</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="tickets"><img src="/assets/module-details-pane/Ticket.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Tickets</Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item>
                                    <Nav.Link eventKey="user-management"><img src="/assets/module-details-pane/Info.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Users</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="blog"><img src="/assets/module-details-pane/Book.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Blog</Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="modules" title="Modules">
                                    <ModulesView />
                                </Tab.Pane>
                                <Tab.Pane eventKey="pricing-structure" title="Pricing Structure">
                                    <PricingStructureView />
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="tickets" title="Tickets">
                                    <TicketsView />
                                </Tab.Pane> */}
                                <Tab.Pane eventKey="user-management" title="User Management">
                                    <UsersView />
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="blog" title="Blog Management">
                                    <BlogManagement />
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </Container>
    )
}