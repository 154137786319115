import "./store-front.scss"

import Col from 'react-bootstrap/Col'
import Container from "react-bootstrap/Container"
import { CreditsPolicyView } from "./credits-policy-view/credits-policy-view"
import { FaqView } from "./faq-view/faq-view"
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import { StoreModulesView } from "./modules-view/modules-view"
import Tab from 'react-bootstrap/Tab'

export function StoreFrontPage() {
    //? Build this way in case we want use contentful
    const mailtoAddress = "info@maluti-x.com";
    const mailtoSubject = "Maluti-X Solutions Portal Query";
    const mailtoBody = "Hi,%0D%0A%0D%0AI'd like to know more about the Maluti-X Solutions Portal!%0D%0A%0D%0A<Your message>";
    const mailToLink = `mailto:${mailtoAddress}?subject=${mailtoSubject}&body=${mailtoBody}`


    return (
        <Container fluid className="h-100 w-100 p-5">
            <Container fluid className="store-front-custom-tabs-container h-100 w-100">
                <Tab.Container defaultActiveKey="modules">
                    <Row>
                        <Col sm={3} className="store-front-custom-tabs">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="modules"><img src="/assets/module-details-pane/SquaresFourWhite.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Solutions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="credit-policy"><img src="/assets/module-details-pane/Coin.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Credit Policy</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="faq"><img src="/assets/module-details-pane/ChatsTeardrop.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;FAQ</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <a href={mailToLink} rel="noreferrer" target="_blank" className="nav-link" id="learn-more"><img src="/assets/module-details-pane/Info.svg" alt="module-icon" className="icon" />&nbsp;&nbsp;Learn More</a>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="modules" title="Modules">
                                    <StoreModulesView />
                                </Tab.Pane>
                                <Tab.Pane eventKey="credit-policy" title="Credit Policy">
                                    <CreditsPolicyView/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="faq" title="faq">
                                    <FaqView />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </Container>
    )
}
