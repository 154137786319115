import "./more-details-pane.scss"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useState } from "react";
import { FoundationModule } from "../../../../models/module";
import { CategoryButton } from "../../../../components/category-button/category-button";

export function MoreDetailsPane(props) {

    const moduleData: FoundationModule = props.data;
    const moduleMediaUrls: string[] = props.moduleMediaUrls

    let qaulifiedAccessUrl = moduleData.accessUrl;
    if (qaulifiedAccessUrl && !qaulifiedAccessUrl.includes("http")) {
        qaulifiedAccessUrl = "https://" + qaulifiedAccessUrl;
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    return (
        <>
            <Button variant="primary" className="float-right" onClick={handleShow}>
                More details
            </Button>
            <Modal show={show} onHide={handleClose} fullscreen={true} backdrop={true} animation={true}>
                <Modal.Body id="details-pane-modal">
                    <Row className="h-100" style={{ overflowY: 'scroll' }}>
                        <Col className="main-info-col">
                            <Row className="title-row">
                                <img src="/assets/module-details-pane/SquaresFour.svg" alt="module-icon" className="icon" />
                                <h2>{moduleData.name}</h2>
                                <img src="/assets/module-details-pane/XSquare.svg" alt="cross-button" className="close-button" onClick={handleClose} />
                            </Row>
                            <Row>
                                <Col>
                                    <h3>Description</h3>
                                    <span>{moduleData.description}</span>
                                </Col>
                                <Col>
                                    <h3>Objective</h3>
                                    <span>{moduleData.objectiveDescription}</span>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <h3>Inputs</h3>
                                    <span>{moduleData.inputDescription}</span>
                                </Col>
                                <Col>
                                    <h3>Outputs</h3>
                                    <span>{moduleData.outputDescription}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="created-by">
                                    Created by <b>{moduleData.author}</b>
                                </Col>
                                <Col md={7} className="categories">
                                    {moduleData.categories.map(category => (
                                        <CategoryButton data={category}></CategoryButton>
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Tabs defaultActiveKey="media" className="mb-3, centered-nav">
                                    <Tab eventKey="media" title="Media" className="py-4">

                                        {moduleMediaUrls.length > 0 ?
                                            <Card className="p-3 media-row row">
                                                {
                                                    moduleMediaUrls.map(url => (
                                                        <Col className="media-col">
                                                            <Card className="module-media-card">
                                                                <Card.Img variant="top" src={url} className='module-media-img' />
                                                            </Card>
                                                        </Col>

                                                    ))
                                                }
                                            </Card>
                                            :
                                            <h2>No media available</h2>
                                        }
                                    </Tab>
                                </Tabs>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
