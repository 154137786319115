import { useAuth0 } from "@auth0/auth0-react";
import Axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useAppDispatch } from "../../../../app/hooks";
import { Ticket } from "../../../../models/ticket";
import { TicketResponse } from "../../../../models/ticket-response";
import { removeUser } from "../../../../slices/apex-data/apex-data-slice";
import { errorToast, successToast } from "../../../../utils/toast-utils";
import { useState } from "react";

export function DeleteUserModal(props) {

    const [show, setShow] = useState(false);
    const [deleteIsLoading, setDeleteLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useAppDispatch();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    const handleDeleteUser = async () => {
        try {
            const Id: string = props.Id;
            setDeleteLoading(true);
            const accessToken = await getAccessTokenSilently();
            await Axios.delete<string>(`${process.env.REACT_APP_CORE_SERVER_URL}api/auth0/${Id}`, { headers: { Authorization: `Bearer ${accessToken}` } });

            dispatch(removeUser(Id))
            successToast(`User successfully deleted.`)
        } catch (error: any) {
            errorToast("Error deleting user")
        } finally {
            setDeleteLoading(false);
            setShow(false);
        }
    }

    return (
        <>
            <><Button variant="primary" onClick={handleShow}>
                Delete User
            </Button>
                <Modal show={show} onHide={handleClose} backdrop={true} animation={false} centered contentClassName="custom-modal-content">
                    <Modal.Header>
                        <Modal.Title>User Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you wish to delete user {props.email} with unique Identifier {props.Id}
                        <Row className="w-100">
                            <Col>
                                <Button style={{ background: "red" }} className="w-100" onClick={handleDeleteUser} disabled={deleteIsLoading}>
                                    {deleteIsLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Delete</span>}

                                </Button>
                            </Col>
                            <Col md={4}>
                            </Col>
                            <Col md={4}>
                                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={deleteIsLoading}>
                                    {deleteIsLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Cancel</span>}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        </>
    );
}