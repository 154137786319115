import "./users.scss"
import Container from "react-bootstrap/Container"
import Table from "react-bootstrap/Table";
import { Button, Spinner } from "react-bootstrap";
import { EditUserRoleModal } from "./edit-user-role-modal/edit-user-role-modal";
import { getAllUsersAsync, selectUsers, selectUsersError, selectUsersIsFetching } from "../../../slices/apex-data/apex-data-slice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DeleteUserModal } from "./delete-user-modal/delete-user-modal";
import { MagnifyingGlass} from "phosphor-react";
import { useState } from "react";

export function UsersView() {

    const users = useAppSelector(selectUsers);
    const isFetching = useAppSelector(selectUsersIsFetching);
    const error = useAppSelector(selectUsersError);
    const dispatch = useAppDispatch();
    const [filterItem, setFilterEmail] = useState<string>("");

    const filteredUsers = users?.filter(e => e.email?.includes(filterItem));
    
    if (!isFetching && users === undefined && !error) {
        dispatch(getAllUsersAsync());
    }
    return (
        <Container fluid>
            <h1>Users</h1>
            <div style={{position:"relative"}}>
                <div className="ticketSearch" style={{ paddingRight: "5vw" }}>
                    <MagnifyingGlass size={20} />
                    <input
                        className="ticketSearch-in"
                        type="text"
                        value={filterItem}
                        onChange={e => setFilterEmail(e.target.value)}
                        placeholder="Search for users..." ></input>

                </div>
                

            </div>
            {!isFetching && filteredUsers !== undefined ? (
                
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.filter(item => item).map(item => (
                            <tr>
                                <td>{item.email}</td>
                                
                                <td><DeleteUserModal email={item.email} Id={item.uniqueIdentifier}></DeleteUserModal></td>
                                {/* <td><EditUserRoleModal></EditUserRoleModal></td>  might use in future when editing more about a user*/}
                                
                            </tr>

                        ))}
                    </tbody>
                </Table>
            ) : (
                <Spinner animation="border" variant="light" role="status">
                    <   span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </Container>
    )
}
