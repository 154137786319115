import "./jira-tickets.scss";

import { useEffect, useState } from 'react';

import Axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner"
import axios from 'axios';
import moment from 'moment';
import { useAuth0 } from "@auth0/auth0-react";

export const JiraTickets =() => {
    const [tickets, setTickets] = useState([]);
    const [selectedIssue, setSelectedIssue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { getAccessTokenSilently, user } = useAuth0();


    useEffect(() => {
      async function fetchData(){
        setIsLoading(true);
        const userEmail = user.email;
        //TODO add and set env and userEmail     
        const url = `${process.env.REACT_APP_CORE_SERVER_URL}api/ticket/jira/${userEmail}` 
        const token = await getAccessTokenSilently();
        axios.get(url, { headers: { Authorization: `Bearer ${token}`, } }
        )
          .then(response => {

            //const filteredTickets = response.data.issues.filter(x => x.fields.reporter?.emailAddress === userEmail) 
            const filteredTickets = response.data.issues.filter(x => x.fields.project?.key === 'SPCS')
            setTickets(filteredTickets)
            
            setIsLoading(false);
            //setTickets(response.data.issues);
          })
          .catch(error => {
          });
          
      }
          fetchData();
      }, []);

      function handleIssueClick(issue) {
        setSelectedIssue(issue);
        handleOpenModal(true)
      }

      const handleOpenModal = () => setIsOpen(true);
    
      const handleCloseModal = ()=> {
        //setShowInput(false);
        setSelectedIssue(null);
        setIsOpen(false)
      }

    return(
    <div className="container bob">
      {tickets && !isLoading ? (<>
      <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th>Ticket</th>
          <th>Summary</th>
          <th>Reporter</th>
          <th>Assignee</th>
          <th>Status</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>

        {tickets.map(issue => (
          <tr className="table-row" key={issue.id} onClick={() => handleIssueClick(issue)}>
            <td>{issue.key}</td>
            <td>{issue.fields.summary}</td>
            <td>{issue.fields.reporter.displayName}</td>
            <td>{issue.fields.assignee ? issue.fields.assignee.displayName : 'Unassigned'}</td>
            <td>{issue.fields.status.name}</td>
            <td>{issue.fields.created}</td>
          </tr>
        ))}

      </tbody>
    </table>
    </>) : (<Spinner animation="border" variant="dark" role="status">
                        <   span className="visually-hidden">Loading...</span>
                    </Spinner>)
    }
    
    {isOpen && selectedIssue ? ( 
        <IssueModal issue={selectedIssue} onClose={handleCloseModal} />
       ) : (<></>)}
    </div>
    )



    
    
}

function IssueModal({ issue, onClose }) {
  
const [selectedIssue, setSelectedIssue] = useState(issue);
const [isLoading, setIsLoading] = useState(false);
const { getAccessTokenSilently, user } = useAuth0();
  const [ticketComments, setTicketComments] = useState([]);
  //setTicketComments(comments)
  const [commentBody, setCommentBody] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(true);

  const comment = {
    body: commentBody,
    //issueId: props.issueId,
  };

  const handleCommentButtonClick = () => {
    setShowAddCommentButton(false)
    setShowInput(true);
  };

  const handleCommentSaveButtonClick = () => {
    setShowInput(false);
    //setShowAddCommentButton(false);
    // Handle saving input value here
    postJiraComment();
  };

  useEffect(() => {
    async function fetchData () {
        
        setIsLoading(true)
      const issueId = selectedIssue.key;
      const token = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_CORE_SERVER_URL}api/ticket/jiraTicketComments/${issueId}`
      axios.get(url, { headers: { Authorization: `Bearer ${token}`, } }
           )
             .then(response => {
               const data = response.data;
               setTicketComments(data)
               setIsLoading(false)
               //setTickets(response.data.issues);
             })
             .catch(error => {
             });
    }
        fetchData();
    }, []);

    const postJiraComment = async () => {
      try {
        const comment = commentBody;
        const token = await getAccessTokenSilently(); // replace with your actual auth token
        const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/ticket/comment`
        //const postUrl = `https://localhost:44378/api/ticket/comment`
        const data = {
          comment: commentBody,
          issueId: selectedIssue.key,
          userEmail: user.email
        }
        const response = await Axios.post(postUrl, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
      }
    };




  return (
    <Modal show={issue} onHide={!issue}>
                    <Modal.Header>
                        <Modal.Title>{issue.fields.summary}</Modal.Title>
                    </Modal.Header>
                    
                    
                        <Modal.Body>
                          <div>
                          <div class="border p-3">
                            <p><strong>Key:</strong> {issue.key}</p>
                                        <p><strong>Reporter:</strong> {issue.fields.reporter.displayName}</p>
                                        <p><strong>Assignee:</strong> {issue.fields.assignee ? issue.fields.assignee.displayName : 'Unassigned'}</p>
                                        <p><strong>Status:</strong> {issue.fields.status.name}</p>
                                        <p><strong>Created:</strong> {issue.fields.created}</p>
                                        <p><strong>Description:</strong> {issue.fields.description.content[0].content[0].text}</p>
                          </div>

                          <div class="h4">
                            Comments
                          </div>
                          {ticketComments && !isLoading ?(<>
                                {ticketComments && ticketComments.map((comment, index) => (
                                <div key={comment.id} class="row" style={{ backgroundColor: index % 2 === 0 ? 'var(--ticket-table-buttons)' : 'var(ticket-table-buttons2)' }}>
                                   <p class="col-4">{comment.author.displayName}</p>
                                  <p class="col-8">{moment(comment.created).fromNow()}</p>
                                  <p>{comment.body.content[0].content[0].text}</p> 

                                </div>
                              ))}
                              
                          
                          <div>
                            {/* { showAddCommentButton && <Button onClick={handleCommentButtonClick} variant="secondary">Add comment</Button>}
                            {showInput && (
                                <div>
                                  <label>
                                Comment:
                                  <input type="text" value={commentBody} onChange={(event) => setCommentBody(event.target.value)}/>
                                  </label>
                                  <div><Button variant="secondary" onClick={handleCommentSaveButtonClick}>Save</Button>
                                  <Button variant="danger">Cancel</Button></div>
                                  
                                </div>
                              )} */}
                            </div>
                            
                          </>):(<Spinner animation="border" variant="dark" role="status">
                                          <   span className="visually-hidden">Loading...</span>
                                      </Spinner>)}
                                      </div>
                        </Modal.Body>
                        <div class="d-flex justify-content-center">
                           <Button variant="primary" className="w-50 text-centre" onClick={onClose}>
    Close
    </Button>
                        </div>
   

    </Modal>
  );
}