import "./edit-module-modal.scss"

import Axios, { AxiosResponse } from "axios";
import { FoundationModule, FoundationModuleCategory } from "../../../../../models/module";
import { errorToast, successToast } from "../../../../../utils/toast-utils";
import { getAllCompaniesAsync, selectCompanies, selectCompaniesError, selectCompaniesIsFetching } from "../../../../../slices/apex-data/apex-data-slice";
import { getModuleCategoriesAsync, selectApexModuleCategories, selectApexModulesCategoriesError, selectApexModulesCategoriesIsFetching, updateModule } from "../../../../../slices/apex-data/apex-modules-slice";
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import { CategoryButton } from "../../../../../components/category-button/category-button";
import Col from "react-bootstrap/Col";
import { Company } from "../../../../../models/company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner';
import { getFormValue } from "../../../../../utils/form-utils";
import { useAuth0 } from "@auth0/auth0-react";

export function EditModuleModal(props) {
  const innitialState : Company[] = [];
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState(innitialState) ;
  let originalData: FoundationModule = props.data;
  const closeCallback = props.closeCallback;
  const companies = useAppSelector(selectCompanies);
  const isFetching = useAppSelector(selectCompaniesIsFetching);
  const error = useAppSelector(selectCompaniesError);
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  let companiesList : Company[] = [];
  const [updatedList, setUpdatedList] = useState(innitialState);
  
  //TODO 2 filter the companies and map which ones are selected
  const categories = useAppSelector(selectApexModuleCategories);
  const categoriesIsFetching = useAppSelector(selectApexModulesCategoriesIsFetching);
  const categoriesError = useAppSelector(selectApexModulesCategoriesError);

  if (!isFetching && companies === undefined && !error) {
    dispatch(getAllCompaniesAsync());
  }

  if (!categoriesIsFetching && categories === undefined && !categoriesError) {
    dispatch(getModuleCategoriesAsync());
  }

  // const [show, setShow] = useState(props.shown);
  const [publicFlag, setPublicFlag] = useState(originalData.public);
  const handlePublicFlagChange = (event) => {
    setPublicFlag(event.target.checked)
  };
  

  const handleClose = () => {
    // setShow(false)
    closeCallback()
  };

const handleSearchChange = event => {
  setSearchTerm(event.target.value);
}
const handleCheckboxChange = (event, item:Company) => {
  

  if (event.target.checked) {
    const updatedArray = selectedCompanies.map(y => {
  
    //const matchingItem = originalData.moduleCompanyRelationships?.some( x => x.companyGuid === y.uniqueIdentifier);
    if(y.uniqueIdentifier === item.uniqueIdentifier){
      
      return {...y, enabled:true}
    }
    else{
      return y
    }

  //if(originalData.moduleCompanyRelationships?.some(x => x.companyGuid === y.uniqueIdentifier)){
  // return {...y, enabled: true}
  //   
  //}
  //else {
  //  return y
  //}
})
setSelectedCompanies(updatedArray)
}else{
  const updatedArray = selectedCompanies.map(y => {
      if(y.uniqueIdentifier === item.uniqueIdentifier){
      
      return {...y, enabled:false}
    }
    else{
      return y
    }
})
setSelectedCompanies(updatedArray)
}
 //if (event.target.checked) {
 //  setSelectedCompanies([...selectedCompanies, item]);
 //} else {
 //  setSelectedCompanies(selectedCompanies.filter(selectedItem => selectedItem.uniqueIdentifier !== item.uniqueIdentifier));
 //}
};
if(companies !== undefined){

    companiesList = companies.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
}
  // const [publicFlag, setPublicFlag] = useState(true);
  // const handlePublicFlagChange = (event) => {
  //   setPublicFlag(event.target.checked)
  // };

  //TODO: Might not be needed
  // const [selectedCompany, setSelectedCompany] = useState(undefined);
  // const [selectedCompanyName, setSelectedCompanyName] = useState(undefined);
  // const handleCompanyChange = (event) => {
  //   const options = event.target.options;
  //   const selectedIndex = options['selectedIndex']
  //   setSelectedCompany(options[selectedIndex].value)
  //   setSelectedCompanyName(options[selectedIndex].text)
  // };

  const initialCategoriesSelected = {}

  if (categories) {
    for (let category of categories) {
      
      initialCategoriesSelected[category.uniqueIdentifier] = false
      if(originalData.categories !== null && originalData.categories[0] !== null){        
        if (originalData.categories.some(c => c.uniqueIdentifier === category.uniqueIdentifier)) {
        initialCategoriesSelected[category.uniqueIdentifier] = true
      }
      }
      
    }
  }
  
  const [selectedCategories, setSelectedCategories] = useState(initialCategoriesSelected);

  const handleSelectCategory = (category: FoundationModuleCategory) => {
    const currentSelectedCategories = JSON.parse(JSON.stringify(selectedCategories))
    currentSelectedCategories[category.uniqueIdentifier] = !currentSelectedCategories[category.uniqueIdentifier];
    setSelectedCategories(currentSelectedCategories)
  }

  useEffect(() => {
    if(originalData.moduleCompanyRelationships !== undefined){
      const updatedArray = companiesList.map(y => {
    
      const matchingItem = originalData.moduleCompanyRelationships?.some( x => x.companyGuid === y.uniqueIdentifier);
      if(matchingItem){
        
        return {...y, enabled:true}
      }
      else{
        return {...y, enabled:false}
      }

    //if(originalData.moduleCompanyRelationships?.some(x => x.companyGuid === y.uniqueIdentifier)){
    // return {...y, enabled: true}
    //   
    //}
    //else {
    //  return y
    //}
  })
  setSelectedCompanies(updatedArray)
    }
  }, [originalData.moduleCompanyRelationships]);
  




  const checkIfChecked =(Id:string) =>{
    

      if(originalData.moduleCompanyRelationships?.some(x => x.companyGuid === Id)){
     
        return true
      }
      else return false
      
  }

  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }

    const name = getFormValue(form, 'formName');
    const url = getFormValue(form, 'formUrl');
    const author = getFormValue(form, 'formAuthor');
    const description = getFormValue(form, 'formDescription');
    const objectiveDescription = getFormValue(form, 'formObjectiveDescription');
    const inputDescription = getFormValue(form, 'formInputDescription');
    const outputDescription = getFormValue(form, 'formOutputDescription');
    const selectedCategoriesList: FoundationModuleCategory[] = [] 
    const selectCompanies: Company[] = [];

    if(selectedCompanies.length > 0){
      for(let company of selectedCompanies){
        if(company.enabled === true){
          selectCompanies.push(company)
        }
        
      }
    }

    if (categories)
    for (let category of categories) {
      if (selectedCategories[category.uniqueIdentifier]) {
        selectedCategoriesList.push(category)
      }
    }

    const putModel = {
      uniqueIdentifier: originalData.uniqueIdentifier,
      name: name,
      accessUrl: url,
      author: author,
      description: description,
      objectiveDescription: objectiveDescription,
      inputDescription: inputDescription,
      outputDescription: outputDescription,
      categories: selectedCategoriesList,
      companies: selectCompanies,
      public: publicFlag
    } as FoundationModule;
    setValidated(true);
    event.preventDefault();

    try {

      const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
      const accessToken = await getAccessTokenSilently();
      const endpoint = `${serverUrl}api/module`;
      var res = await Axios.put<any, AxiosResponse<FoundationModule>>(endpoint, putModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
      dispatch(updateModule(res.data))
      successToast(`Module ${name} updated successfully`)
      handleClose();
    } catch (error: any) {
      errorToast(error.response.data)
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={props.shown} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
        <Modal.Header id="create-module-header">
          <Modal.Title>View/Edit Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="w-100 h-100">
              <Col>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Module Name*</Form.Label>
                  <Form.Control type="text" required defaultValue={originalData.name} />
                  <Form.Control.Feedback type="invalid">Please provide a name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formUrl">
                  <Form.Label>Module URL*</Form.Label>
                  <Form.Control type="text" required defaultValue={originalData.accessUrl} />
                  <Form.Control.Feedback type="invalid">Please provide a URL.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAuthor">
                  <Form.Label>Module Author*</Form.Label>
                  <Form.Control type="text" required defaultValue={originalData.author} />
                  <Form.Control.Feedback type="invalid">Please provide an author.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>Module Description*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.description} />
                  <Form.Control.Feedback type="invalid">Please provide a module description.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formObjectiveDescription">
                  <Form.Label>Description of module objectives*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.objectiveDescription} />
                  <Form.Control.Feedback type="invalid">Please provide a description of the module objectives.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formInputDescription">
                  <Form.Label>Description of possible inputs*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.inputDescription} />
                  <Form.Control.Feedback type="invalid">Please provide a description of the possible inputs.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOutputDescription">
                  <Form.Label>Description of possible outputs*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.outputDescription} />
                  <Form.Control.Feedback type="invalid">Please provide a description of the possible outputs.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCategories">
                  <Form.Label>Categories*</Form.Label>
                  {!categoriesIsFetching && categories !== undefined ? (
                    <>
                      {categories.map(category => (
                        <>
                        <Row onClick={() => handleSelectCategory(category)} >
                          <Col md={5} className='category-col'>
                              <CategoryButton data={category} ></CategoryButton>
                          </Col> 
                          <Col md={{span: 1, offset: 5}}>
                              { selectedCategories[category.uniqueIdentifier] && <FontAwesomeIcon icon="check" className="icon" /> }
                          </Col> 
                        </Row>
                        <br/>
                        </>
                      ))}
                    </>
                  ) : (
                    <Spinner animation="border" variant="dark" role="status">
                      <   span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </Form.Group>
                <Row className="w-100">
                  <Col>
                    <Form.Group className="mb-3" controlId="formPublicFlag">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Public module"
                        defaultChecked={publicFlag}
                        onChange={handlePublicFlagChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!publicFlag ? (<div>
                  <div >Assign companies to private Module</div>
                  <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Search Companies" />
                  <table>
                    <thead>
                      <tr>
                        <th>Company</th>
                        <th>Guid</th>
                        <th>Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCompanies.map(item => (
                        <tr key={item.uniqueIdentifier}>
                          <td>{item.name}</td>
                          <td>{item.uniqueIdentifier}</td>
                          <td><input type="checkbox" checked={item.enabled} onChange={event => handleCheckboxChange(event, item)} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>) : ("")}
                
              </Col>
            </Row>
            <Row className="w-100">
              <Col md={{ span: 4, offset: 4 }}>
                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading}>
                  Cancel
                </Button>
              </Col>
              <Col md={4}>
                <Button type="submit" className="w-100" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Save</span>}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

