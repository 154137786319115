import "./credits-policy-view.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import { useContentful } from "react-contentful";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useState } from "react";


export function CreditsPolicyView() {
    const [content, setContent] = useState('');
    const [mediaUrls, setMediaUrls] = useState([] as string[]);
    const { data, error, fetched, loading } = useContentful({
        contentType: 'creditsPolicyPageContent',
    });

    if (!error && !loading && fetched) {
        if (fetched && data && content === '') {
            // TODO: Figure out how to use this because it should work but doesn't
            const options = {
                renderText: text =>
                    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
            };

            let newContent = documentToHtmlString((data as any).items[0].fields.content, options as any)
            newContent = newContent.replace(/\n/g, '<br/>');
            setContent(newContent);

            let newMediaUrls = [] as string[]
            if ((data as any).items.length > 0 && (data as any).items[0].fields.explanatoryImages && (data as any).items[0].fields.explanatoryImages.length > 0) {
                for (let mediaItem of (data as any).items[0].fields.explanatoryImages) {
                    newMediaUrls.push(`https:${mediaItem.fields.file.url}`);
                }
            }
            setMediaUrls(newMediaUrls);
        }
    }

    return (
        <>
            <Row><h1>Credits Policy</h1></Row>
            <Row dangerouslySetInnerHTML={{ __html: content }}>
            </Row>
            {mediaUrls.length > 0 ?
                <Row>
                    <Card className='m-auto p-3' style={{ width: 'fit-content' }}>
                        <Row>
                            {mediaUrls.map(url => (
                                <Col>
                                    <Card style={{ width: 'fit-content' }}>
                                        <Card.Img variant="top" src={url} className='credits-policy-page-img' />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                </Row>
                : <></>}
        </>
    )
}
