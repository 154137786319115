import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import apexDataReducer from '../slices/apex-data/apex-data-slice';
import apexModulesReducer from '../slices/apex-data/apex-modules-slice';
import blogDataReducer from "../slices/blog/blog-slice";
import companiesReducer from '../slices/company/company-slice';
import modulesReducer from '../slices/module/modules-slice';
import ticketReducer from '../slices/tickets/ticket-slice';
import userDbReducer from "../slices/user/user-db-slice";

export const store = configureStore({
  reducer: {
    company: companiesReducer,
    apexData: apexDataReducer,
    apexModule: apexModulesReducer,
    module: modulesReducer,
    tickets: ticketReducer,
    user: userDbReducer,
    blog: blogDataReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
 