import "./add-pricing-structure-modal.scss";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { selectCompanies, selectCompaniesError, selectCompaniesIsFetching, getAllCompaniesAsync, addPricingStructure } from "../../../../slices/apex-data/apex-data-slice";
import Axios, { AxiosResponse } from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { errorToast, successToast } from "../../../../utils/toast-utils";
import { getFormValue } from "../../../../utils/form-utils";
import { PricingStructure } from "../../../../models/pricing-structure";

export function AddPricingStructureModal() {
  const companies = useAppSelector(selectCompanies);
  const isFetching = useAppSelector(selectCompaniesIsFetching);
  const error = useAppSelector(selectCompaniesError);
  const dispatch = useAppDispatch();

  if (!isFetching && companies === undefined && !error) {
    dispatch(getAllCompaniesAsync());
  }

  const nowDate = new Date()
  const oneYear = (new Date(nowDate.getFullYear() + 1, nowDate.getMonth(), nowDate.getDate())).toISOString().split('T')[0];
  nowDate.setDate(nowDate.getDate() + 1)
  const now = nowDate.toISOString().split('T')[0];


  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setValidated(false)
    setShow(true)
  };

  const [startNowFlag, setStartNowFlag] = useState(true);
  const [customEndDateFlag, setCustomEndDateFlag] = useState(false);
  const handleStartNowFlagChange = (event) => {
    setStartNowFlag(event.target.checked)
  };

  const handleCustomEndDateFlagChange = (event) => {
    setCustomEndDateFlag(event.target.checked)
  };

  const [forCompanyFlag, setForCompanyFlag] = useState(false);
  const handleForCompanyFlagChange = (event) => {
    setForCompanyFlag(event.target.checked)
  };

  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [selectedCompanyName, setSelectedCompanyName] = useState(undefined);
  const handleCompanyChange = (event) => {
    const options = event.target.options;
    const selectedIndex = options['selectedIndex']
    setSelectedCompany(options[selectedIndex].value)
    setSelectedCompanyName(options[selectedIndex].text)
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }

    const name = getFormValue(form, 'formName');
    const description = getFormValue(form, 'formDescription');
    const fromDate = startNowFlag ? now : getFormValue(form, 'formStartDate');
    const untilDate = customEndDateFlag ? getFormValue(form, 'formEndDate') : null;
    const creditCost = +getFormValue(form, 'formCreditCost');
    const currency = getFormValue(form, 'formCurrency')

    const postModel = {
      "name": name,
      "description": description,
      "fromDate": fromDate,
      "untilDate": untilDate,
      "creditCost": creditCost,
      "currency": currency,
    };
    setValidated(true);
    event.preventDefault();

    try {
      const accessToken = await getAccessTokenSilently();
      const postUrl = forCompanyFlag && selectedCompany ? `${process.env.REACT_APP_CORE_SERVER_URL}api/pricing/${selectedCompany}` : `${process.env.REACT_APP_CORE_SERVER_URL}api/pricing`
      var res = await Axios.post<any, AxiosResponse<PricingStructure>>(postUrl, postModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
      dispatch(addPricingStructure(res.data))
      successToast(`Pricing structure ${forCompanyFlag ? `for ${selectedCompanyName}` : ''} created successfully`)
      handleClose();
    } catch (error: any) {
      errorToast(error.response.data)
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="primary" className="m-auto" onClick={handleShow}>
        <FontAwesomeIcon icon="plus" className="icon" /> Create Pricing Structure
      </Button>
      <Modal show={show} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
        <Modal.Header>
          <Modal.Title>Add a New Pricing Structure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="w-100 h-100">
              <Col>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">Please provide a name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>Description (Optional)</Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
                <Row className="w-100">
                  <Col>
                    <Form.Group className="mb-3" controlId="formStartDateCheck">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Start now?"
                        defaultChecked={startNowFlag}
                        onChange={handleStartNowFlagChange}
                      />
                    </Form.Group>
                    {!startNowFlag ?
                      <Form.Group className="mb-3" controlId="formStartDate">
                        <Form.Label>Start Date *</Form.Label>
                        <Form.Control type="date" required defaultValue={now} />
                      </Form.Group>
                      :
                      <></>
                    }
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formEndDateCheck">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Set end date?"
                        defaultChecked={customEndDateFlag}
                        onChange={handleCustomEndDateFlagChange}
                      />
                    </Form.Group>
                    {customEndDateFlag ?
                      <Form.Group className="mb-3" controlId="formEndDate" >
                        <Form.Label>End Date *</Form.Label>
                        <Form.Control type="date" required defaultValue={oneYear} />
                      </Form.Group>
                      :
                      <></>
                    }
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col>
                    <Form.Group className="mb-3" controlId="formCreditCost">
                      <Form.Label>Credit Cost *</Form.Label>
                      <Form.Control type="number" required />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formCurrency">
                      <Form.Label>Currency *</Form.Label>
                      <Form.Select>
                        <option>ZAR</option>
                        <option>USD</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="formCompanyCheck">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Scope to company?"
                      defaultChecked={forCompanyFlag}
                      onChange={handleForCompanyFlagChange}
                    />
                  </Form.Group>
                  {forCompanyFlag ?
                    <Form.Group className="mb-3" controlId="formCompany" >
                      <Form.Label>Company *</Form.Label>
                      <Form.Select onChange={handleCompanyChange}>
                        {companies?.map(c => (
                          <option value={c.uniqueIdentifier}>{c.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    :
                    <></>
                  }
                </Row>
              </Col>
            </Row>
            <Row className="w-100">
              <Col md={{ span: 4, offset: 4 }}>
                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading}>
                  Cancel
                </Button>
              </Col>
              <Col md={4}>
                <Button type="submit" className="w-100" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Confirm</span>}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
