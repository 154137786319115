import "./join-with-code-modal.scss";

import { errorToast, successToast } from "../../../utils/toast-utils";
import { selectCompanyCreationRedirect, updateCompanyCreationRedirect } from "../../../slices/company/company-slice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import Axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner'
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from 'react-router-dom';
import { useState } from "react";

export function JoinWithCodeModal() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const selectedCompanyCreationRedirectUrl = useAppSelector(selectCompanyCreationRedirect);
    const companyCreationRedirectUrl = selectedCompanyCreationRedirectUrl;
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setValidated(false)
        setShow(true)
    };

    const [validated, setValidated] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const inviteCode = form[0].value;
        setValidated(true);
        event.preventDefault();

        try {
            const accessToken = await getAccessTokenSilently();
            const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/company/invite/accept?inviteCode=${inviteCode}`
            await Axios.put(postUrl, null, { headers: { Authorization: `Bearer ${accessToken}`, } });
            successToast(`Company joined successfully${companyCreationRedirectUrl ? '. You will now be redirected... ' : ''}`)
            handleClose();
            if (companyCreationRedirectUrl){
                dispatch(updateCompanyCreationRedirect(undefined))
                setTimeout(() => {
                    const redirect = companyCreationRedirectUrl.includes("http") ? companyCreationRedirectUrl : "https://"+companyCreationRedirectUrl;
                    window.location.href = redirect;
                }, 5000 )
                return;
            }
                
            history.push('/companies');
            window.location.reload();
        } catch (error: any) {
            //errorToast(error.response?.data ?? error.message)
            errorToast("Invalid join code")
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button variant="outline-success" className="m-auto" onClick={handleShow}>
                Join With Code
            </Button>
            <Modal show={show} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
                <Modal.Header id="join-with-code-header">
                    <Modal.Title>Join Company With Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
                        <br/>
                        <Row className="w-100 h-100">
                            <Col>
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Invite Code *</Form.Label>
                                    <Form.Control type="text" required />
                                    <Form.Control.Feedback type="invalid">Please provide an invite code.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="w-100">
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading }>
                                    Cancel
                                </Button>
                            </Col>
                            <Col md={4}>
                                <Button type="submit" className="w-100" disabled={isLoading }>
                                    { isLoading  ? <Spinner size="sm" animation="border" variant="light" /> : <span>Confirm</span>}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
