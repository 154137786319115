import "./resolution-unsupported-page.scss"
import Container from "react-bootstrap/Container"

export function ResolutionUnsupportedPage() {
    return (
        <Container fluid className="h-100 message-container">
            <h1>This resolution is currently unsupported</h1>
        </Container>
    );
}
