import "./api-keys.scss"

import { getCompanyAsync, selectCompany, selectCompanyError, selectCompanyIsFetching } from "../../../slices/company/company-slice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect, useState } from "react";

import Axios from "axios";
import { Spinner } from "react-bootstrap";
import { errorToast } from "../../../utils/toast-utils";
import { useAuth0 } from "@auth0/auth0-react";

export function ApiKeys({uniqueIdentifier}){

    const { user} = useAuth0();
    const roles: string[] = user && user["https://foundation.com/roles"] ? user["https://foundation.com/roles"] : [];
    const [isLoading, setIsLoading] = useState(true);

    const { getAccessTokenSilently } = useAuth0();

    const [apiKeyExists, setApiKeyExists] = useState<boolean>(false);
    //check if there's already been a token generated
    useEffect(()=> {
        const fetchData = async () => {
            if (roles.includes("milo-external")) { // Check for specific role

            const token = await getAccessTokenSilently();
            const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
            try {
                const response = await Axios.get<boolean>(`${serverUrl}api/auth0/milo/external/key/${uniqueIdentifier}`, { headers: { Authorization: `Bearer ${token}`, } });
                
                const responseData = response.data;
                setApiKeyExists(responseData);
                setIsLoading(false)
                return responseData;
            } catch (error: any) {
                errorToast(error.response.data)
                throw error;
            }
        }
        };

        fetchData();

    }, [])

    const [data, setData] = useState<string>('');

    const fetchData = async () => {
        if (roles.includes("milo-external")) { 

        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        const token = await getAccessTokenSilently();


        const response = await Axios.get<string>(`${serverUrl}api/auth0/milo/external/token/${uniqueIdentifier}`, { headers: { Authorization: `Bearer ${token}`, } });
        const data = await response.data;
        setData(data);
        }
    }



    return (
    
    <div className="container">
         <h1>API Keys</h1>
         {!isLoading ? (<>
                            {user && (roles.includes("Standard") || roles.includes("milo-external")) ?
                        <>
                        <h2>Milo API</h2>
                        <label>Key:</label>
                        {
                            apiKeyExists ? <> <input 
                         id="apiKey"
                         type="text" 
                         value="•••••••••••••" 
                         readOnly 
                         style={{background: '#f0f0f0', border: 'none'}}></input>
                         <button onClick={fetchData}>Generate Key</button>
                         <p style={{marginRight: '10vw',textAlign: 'center', margin: '0 auto', display: 'block',wordWrap: 'break-word'}}>{data}</p> 
                         <p className="warning-text">*Store your key securely, you won't be able to retrieve it after generating one. And will need to create a new one if needed.</p>
                         </>
                          :  <>
                          <input 
                          id="apiKey"
                          type="text" 
                          value="None" 
                          readOnly 
                          style={{background: '#f0f0f0', border: 'none'}}>
                          </input>
                          <button onClick={fetchData}>Generate Key</button>
                          <p style={{marginRight: '10vw',textAlign: 'center', margin: '0 auto', display: 'block',wordWrap: 'break-word'}}>{data}</p> 
                            <p className="warning-text">*Store your key securely, you won't be able to retrieve it after generating one. And will need to create a new one if needed.</p>
                          </>
                        }
                        </>
                        :
                        <>
                        Your company is not configured to use our Api services. Contact us to find out more.
                        </>
                    }

    </>) : (<Spinner animation="border" variant="dark" role="status">
                        <   span className="visually-hidden">Loading...</span>
                    </Spinner>
    )}
    </div>)
}