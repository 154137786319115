import Axios from "axios";
import { Company, CompanyInvite } from "../../models/company";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompaniesState } from "./company-state";
import { RootState } from '../../app/store';
import { useAuth0 } from "@auth0/auth0-react";
import { errorToast } from "../../utils/toast-utils";

const initialState: CompaniesState = {
    company: undefined,
    companyIsFetching: false,
    companyHasError: false,
    companyInvitesIsFetching: false,
    companyInvitesHasError: false,
    companyCreationRedirect: undefined
};

export const getCompanyAsync = createAsyncThunk(
    'companies/getCompany',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<Company>(`${serverUrl}api/company/root`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

//TODO: Potentially add another thunk here to separately get users to break up calls rather than getting massive models all at once

export const getCompanyInvitesAsync = createAsyncThunk(
    'companies/getCompanyInvites',
    async (companyIdentifier: string) => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
        try {
            const response = await Axios.get<CompanyInvite[]>(`${serverUrl}api/company/${companyIdentifier}/invite`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;
            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
);

export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        addSubCompany: (state, action: PayloadAction<Company>) => {
                state.company?.children?.push(action.payload);
        },
        updateCompany: (state, action: PayloadAction<Company>) => {
            if (state.company === undefined){
                return;
            } 
            state.company = action.payload;
        },
        addUserInvite: (state, action: PayloadAction<CompanyInvite>) => {
                state.company?.invites?.push(action.payload);
        },
        updateCompanyCreationRedirect: (state, action: PayloadAction<string | undefined>) => {
            state.companyCreationRedirect = action.payload;
        },
    },
    // pending
    // fulfilled
    // rejected
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyAsync.pending, (state) => {
                state.companyHasError = false;
                state.companyIsFetching = true;
            })
            .addCase(getCompanyAsync.fulfilled, (state, action) => {
                state.companyIsFetching = false;
                state.company = action.payload;
            })
            .addCase(getCompanyAsync.rejected, (state, action) => {
                state.companyIsFetching = false;
                state.companyHasError = true;
            })
            .addCase(getCompanyInvitesAsync.pending, (state) => {
                state.companyInvitesHasError = false;
                state.companyInvitesIsFetching = true;
            })
            .addCase(getCompanyInvitesAsync.fulfilled, (state, action) => {
                state.companyInvitesIsFetching = false;
                if (state.company)
                    state.company.invites = action.payload;
            })
            .addCase(getCompanyInvitesAsync.rejected, (state, action) => {
                state.companyInvitesIsFetching = false;
                state.companyInvitesHasError = true;
            });
    },
});

export const selectCompany = (state: RootState) => state.company.company;

export const selectCompanyIsFetching = (state: RootState) => state.company.companyIsFetching;
export const selectCompanyError = (state: RootState) => { return state.company.companyHasError };

export const selectCompanyInvitesIsFetching = (state: RootState) => state.company.companyInvitesIsFetching;
export const selectCompanyInvitesError = (state: RootState) => { return state.company.companyInvitesHasError };

export const selectCompanyCreationRedirect = (state: RootState) => state.company.companyCreationRedirect;

export const { addSubCompany, updateCompany, addUserInvite, updateCompanyCreationRedirect } = companiesSlice.actions;


export default companiesSlice.reducer;