import "./profile.scss"

import Axios, { AxiosResponse } from "axios";
import { addTicket, getUserTicketsAsync, selectTicketTypes, selectUserTickets, selectUserTicketsHasError, selectUserTicketsIsFetching } from "../../slices/tickets/ticket-slice";
import { errorToast, successToast } from "../../utils/toast-utils";
import { getCompanyAsync, selectCompany, selectCompanyError, selectCompanyIsFetching } from "../../slices/company/company-slice";
import { getUserDbAsync, selectUserDb, selectUserDbHasError, selectUserDbIsFetching } from "../../slices/user/user-db-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { ApiKeys } from "./api-keys/api-keys";
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import DropdownButton from "react-bootstrap/DropdownButton"
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { JiraTickets } from "../../components/jira-tickets/jira-tickets";
import { MagnifyingGlass } from "phosphor-react";
import Modal from "react-bootstrap/Modal"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Spinner from 'react-bootstrap/Spinner'
import Tab from "react-bootstrap/Tab"
import { Ticket } from "../../models/ticket";
import { TicketSummary } from "../../components/ticket-summary/ticket-summary";
import { UserTickets } from "./userTickets/user-tickets";
import { useAuth0 } from "@auth0/auth0-react";
import { useSortableData } from "../../components/sortable-data/sortable-data";
import { useState } from 'react';

export function ProfilePage() {
    const { user, isAuthenticated} = useAuth0();
    const roles = user && user["https://foundation.com/roles"] ? user["https://foundation.com/roles"] : [];
    const hasExternalApiRole = roles.includes('milo-external');

    const dispatch = useAppDispatch();

    const company = useAppSelector(selectCompany);
    const isFetching = useAppSelector(selectCompanyIsFetching);
    const error = useAppSelector(selectCompanyError);
    if (!isFetching && company === undefined && !error) {
        dispatch(getCompanyAsync());
    }
    const [currentTheme, setCurrentTheme] = useState<string | null>(localStorage.getItem("theme"))
    const ticketTypes = useAppSelector(selectTicketTypes);
    //const userTickets = useAppSelector(selectUserTickets);
    //const userTicketsIsFetching = useAppSelector(selectUserTicketsIsFetching);
    //const userTicketsHasError = useAppSelector(selectUserTicketsHasError);
    const userDb = useAppSelector(selectUserDb);
    const userDbIsFetching = useAppSelector(selectUserDbIsFetching);
    const userDbHasError = useAppSelector(selectUserDbHasError);
    const { getAccessTokenSilently } = useAuth0();
    const [filterItem, setFilterItem] = useState<string>("");
    const [filterStatus, setFilterStatus] = useState<string>("");
    const [filterType, setFilterType] = useState<string>("");
    const [show, setShow] = useState(false);
    const [deleteIsLoading, setDeleteIsLoading] = useState(false);
    const [changePasswordIsLoading, setChangePasswordIsLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }
    const nowDate = new Date();
    //const filteredUserTickets = userTickets?.filter(e => e.email?.includes(filterItem) || e.summary?.includes(filterItem) || e.description?.includes(filterItem))
    //    //.filter(e => e.description?.includes(filterDescription))  //If you want to filter description seperately
    //    .filter(e => e.status?.includes(filterStatus))
    //    .filter(e => e.type?.includes(filterType));

    const setType = (e) => {
        setFilterType(e);
    }
    const setStatus = (e) => {
        setFilterStatus(e);
    }

    //if (!userTicketsIsFetching && userTickets === undefined && !userTicketsHasError) {
    //    dispatch(getUserTicketsAsync());
    //}

    if (!userDbIsFetching && userDb === undefined && !userDbHasError) {
        dispatch(getUserDbAsync());
    }
    const userEmail = userDb ? Object.values(userDb)[3] : {};
    const userId = userDb ? Object.values(userDb)[0] : {};


    const handleDeleteAccountRequest = async (event) => {
        setDeleteIsLoading(true);

        const type = "Account";
        const status = "new";
        const email = userEmail;
        const summary = "Account deletion request";
        const note = "A user " + userEmail + " with ID " + userId + " wishes to delete their account.";

        const postModel = {
            "type": type,
            "createdDate": nowDate,
            "status": status,
            "email": email,
            "summary": summary,
            "description": note,
            //rest of company does not need to see this, only apex
            // "companyId": companyId
        };
        event.preventDefault();
        try {
            const accessToken = await getAccessTokenSilently();
            const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/ticket`
            var res = await Axios.post<any, AxiosResponse<Ticket>>(postUrl, postModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
            dispatch(addTicket(res.data))
            successToast(`Account removal request sent`)
            handleClose();
        } catch (error: any) {
            errorToast(error.response.data)
        } finally {
            setDeleteIsLoading(false);
        }
    }

    const body = document.body;
    let lightTheme: string = 'light';
    let darkTheme: string = 'dark';
    // let theme: string | null = localStorage.getItem("theme");


    let opp: boolean;
    if (currentTheme === darkTheme) {
        opp = true;
    }
    if (currentTheme === lightTheme) {
        opp = false
    }
    if (currentTheme === null) {
        opp = false
    }

    const switchTheme = () => {
        if (currentTheme === darkTheme) {
            body.classList.replace('dark', 'light')
            localStorage.setItem("theme", "light")
            setCurrentTheme("light")
            handleThemeSubmit();
        } else {
            body.classList.replace('light', 'dark')
            localStorage.setItem("theme", "dark")
            setCurrentTheme("dark")
            handleThemeSubmit();
        }
    }

    const handleThemeSubmit = async () => {
        const patchModel = {
            "theme": currentTheme,
        };

        try {
            const accessToken = await getAccessTokenSilently();
            const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/auth0/metadata/theme?darkTheme=${opp}`
            await Axios.patch(postUrl, patchModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
        } catch (error: any) {
            errorToast(error.response.data)
        }
    };

    const handleChangePassword = async () => {
        setChangePasswordIsLoading(true);
        try {
            const accessToken = await getAccessTokenSilently();
            const getUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/auth0/password/change`
            const res = await Axios.get<string>(getUrl, { headers: { Authorization: `Bearer ${accessToken}`, } });
            window.open(res.data, "_blank")
        } catch (error: any) {
            errorToast(error.response.data)
        } finally {
            setChangePasswordIsLoading(false);
        }
    }

    //sorting code
    //const { items, requestSort, sortConfig } = useSortableData(filteredUserTickets ? filteredUserTickets : []);
    //const getClassNamesFor = (name) => {
    //    if (!sortConfig) {
    //        return;
    //    }
    //    return sortConfig.key === name ? sortConfig.direction : undefined;
    //};

    return (
        <Container fluid className="p-5">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                <Row>
                    <Col sm={4}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first" >User Profile</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={4}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="second">My Tickets</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={4}>
                        {isAuthenticated && hasExternalApiRole && (
                        <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="third">API Keys</Nav.Link>
                        </Nav.Item>
                    </Nav>
      )}
                    </Col>
                </Row>
                <Row>
                    <Tab.Content>
                        <Tab.Pane eventKey="first" className="py-2 w-50">
                            <h1>Overview</h1>
                            <br />
                            <h5>Current Theme : <b>{currentTheme}</b></h5>
                            <Button variant='outline-secondary' onClick={switchTheme}>
                                Change Theme
                            </Button>
                            <br />
                            <hr />
                            <br />
                            {!userDbIsFetching && userDb !== undefined ? (
                                <div>
                                    <h3>Personal info we keep of yours: </h3>
                                    <h5>Email: <b>{Object.values(userDb)[3] as any}</b></h5>
                                    <Button variant='outline-secondary' onClick={handleShow} disabled={deleteIsLoading}>
                                        {deleteIsLoading ? <Spinner size="sm" animation="border" /> : <span>Delete account and remove my content</span>}
                                    </Button>
                                    <br />
                                    <br />
                                    <hr />
                                    <br />
                                    <h5>Change password</h5>
                                    <Button variant='outline-secondary' onClick={handleChangePassword} disabled={changePasswordIsLoading}>
                                        {changePasswordIsLoading ? <Spinner size="sm" animation="border" /> : <span>Change password</span>}
                                    </Button>
                                    <br />
                                    <br />
                                    <hr />
                                    <br />
                                </div>
                            ) : (
                                <Spinner animation="border" variant="light" role="status">
                                    <   span className="visually-hidden">Loading...</span>
                                </Spinner>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Container fluid className="py-4 w-100">
                            <JiraTickets/>                               
                            </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                        {!isFetching && company !== undefined && company.uniqueIdentifier !== undefined ?

                            <ApiKeys uniqueIdentifier={company.uniqueIdentifier}/>
                            :<></>}
                        </Tab.Pane>
                    </Tab.Content>
                </Row>
            </Tab.Container>

            <Modal size="lg" centered show={show} onHide={handleClose} backdrop={true} animation={false}>
                <Modal.Body>
                    Deleting your email address will result in your account being removed, are you sure you wish to continue?
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    {/* log ticket request for account removal, apex user will need to delete account */}
                    <Button onClick={handleDeleteAccountRequest} variant="danger" style={{ marginRight: "5vw", borderRadius: "20px", height: "4.6vh", width: "5vw" }}>Delete</Button>
                    <Button onClick={handleClose} variant="primary">Cancel</Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
}
