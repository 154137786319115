import "./categories-modal.scss"

import Axios, { AxiosResponse } from "axios";
import { addModuleCategory, getModuleCategoriesAsync, removeModuleCategory, selectApexModuleCategories, selectApexModulesCategoriesError, selectApexModulesCategoriesIsFetching } from "../../../../slices/apex-data/apex-modules-slice";
import { errorToast, successToast } from "../../../../utils/toast-utils";
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import Button from "react-bootstrap/Button";
import { CategoryButton } from "../../../../components/category-button/category-button";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { FoundationModuleCategory } from "../../../../models/module";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner';
import { getFormValue } from "../../../../utils/form-utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export function CategoriesModal() {
  const categories = useAppSelector(selectApexModuleCategories);
  const isFetching = useAppSelector(selectApexModulesCategoriesIsFetching);
  const error = useAppSelector(selectApexModulesCategoriesError);
  const dispatch = useAppDispatch();

  if (!isFetching && categories === undefined && !error) {
    dispatch(getModuleCategoriesAsync());
  }

  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setValidated(false)
    setShow(true)
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }

    const name = getFormValue(form, 'formName');

    const postModel = {
      name: name,
    } as FoundationModuleCategory;
    setValidated(true);
    event.preventDefault();

    try {
      const accessToken = await getAccessTokenSilently();
      const postUrl = `${process.env.REACT_APP_MODULES_SERVER_URL}api/category`;
      var res = await Axios.post<any, AxiosResponse<FoundationModuleCategory>>(postUrl, postModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
      dispatch(addModuleCategory(res.data))
      successToast(`Category ${res.data.name} created successfully`)
    } catch (error: any) {
      errorToast(error.response.data)
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = async (category: FoundationModuleCategory) => {
    try {
        // setDeleteLoading(true)
        const accessToken = await getAccessTokenSilently();
        const response = await Axios.delete<string>(`${process.env.REACT_APP_MODULES_SERVER_URL}api/category/${category.uniqueIdentifier}`, { headers: { Authorization: `Bearer ${accessToken}`, } });
        dispatch(removeModuleCategory(response.data))
        successToast(`Category ${category.name} successfully deleted.`)
    } catch (error: any) {
        errorToast(error.response.data)
    } finally {
        // setDeleteLoading(false);
    }
}

  return (
    <>
      <Button variant="primary" className="m-auto create-module-button" onClick={handleShow}>
        Categories
      </Button>
      <Modal show={show} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
        <Modal.Header id="create-module-header">
          <Modal.Title>Module Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Active categories</h4>
          <br/>
          {!isFetching && categories !== undefined ? (
            <>
              {categories.map(category => (
                <>
                  <Row>
                    <Col md={{span: 5, offset: 3}}><CategoryButton data={category}></CategoryButton></Col>
                    <Col>
                      <Button variant='danger' className="float-right" onClick={() => handleDeleteCategory(category)}><FontAwesomeIcon icon="trash-alt" className="icon" /></Button>
                    </Col>
                  </Row>
                  <br />
                </>
              ))}
            </>
          ) : (
            <Spinner animation="border" variant="dark" role="status">
              <   span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          <h4>Add new category</h4>
          <Form className="d-flex align-items-center justify-content-between flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="w-100">
              <Col>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Category Name*</Form.Label>
                  <Form.Control type="text" required />
                  <Form.Control.Feedback type="invalid">Please provide a name.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="w-100">
              <Col md={{ span: 4, offset: 8 }}>
                <Button type="submit" className="w-100" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Add</span>}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

