import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Article } from "../../models/article";
import Axios from "axios";
import { BlogDataState } from "./blog-state";
import { RootState } from "../../app/store";
import { errorToast } from "../../utils/toast-utils";
import { useAuth0 } from "@auth0/auth0-react";

const initialState: BlogDataState = {
    articles: undefined,
    articlesIsFetching: false,
    articlesHasError: false
}

export const getArticles = createAsyncThunk(
    'apexData/getArticles',
    async () => {
        const { getAccessTokenSilently } = useAuth0();
        const token = await getAccessTokenSilently();
        //TODO update url path
        const serverUrl = process.env.REACT_APP_BLOG_SERVER_URL;
        try {
            const response = await Axios.get<Article[]>(`${serverUrl}api/blog/articles`, { headers: { Authorization: `Bearer ${token}`, } });
            const responseData = response.data;

            return responseData;
        } catch (error: any) {
            errorToast(error.response.data)
            throw error;
        }
    }
)

export const blogDataSlice = createSlice({
    name: 'blog-data',
    initialState,
    reducers: {

    },
    // pending
    // fulfilled
    // rejected
    extraReducers: (builder) => {
        builder
            .addCase(getArticles.pending, (state) => {
                state.articlesHasError = false;
                state.articlesIsFetching = true;
            })
            .addCase(getArticles.fulfilled, (state, action) => {
                state.articlesIsFetching = false;
                state.articles = action.payload;
            })
            .addCase(getArticles.rejected, (state, action) =>{
                state.articlesIsFetching = false;
                state.articlesHasError = true
            });
    },
});


export const selectArticles = ( state: RootState) => state.blog.articles;
export const selectArticlesIsFetching = ( state : RootState) => state.blog.articlesIsFetching;
export const selectArticlesHasError = (state: RootState) => {return state.blog.articlesHasError};

export default blogDataSlice.reducer;