import "./modules-view.scss"

import { getCompanyAsync, selectCompany, selectCompanyError, selectCompanyIsFetching } from "../../../slices/company/company-slice";
import { getPublicStoreModulesAsync, getStoreModulesAsync, selectModules, selectModulesError, selectModulesIsFetching, selectPublicModules, selectPublicModulesError, selectPublicModulesIsFetching } from "../../../slices/module/modules-slice";
import { selectApexModules, selectApexModulesError, selectApexModulesIsFetching } from "../../../slices/apex-data/apex-modules-slice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useEffect, useState } from "react";

import Spinner from "react-bootstrap/Spinner"
import { StoreLineCard } from "./store-line-card/store-line-card";
import { getAllModulesAsync } from "../../../slices/apex-data/apex-modules-slice";
import { useAuth0 } from "@auth0/auth0-react";

export function StoreModulesView() {
    const [loadingTimeout, setLoadingTimeout] = useState(false);

    const { user} = useAuth0();
    const roles: string[] = user && user["https://foundation.com/roles"] ? user["https://foundation.com/roles"] : []


    const dispatch = useAppDispatch();
    const publicModules = useAppSelector(selectPublicModules);
    const publicModulesIsFetching = useAppSelector(selectPublicModulesIsFetching);
    const publicModulesError = useAppSelector(selectPublicModulesError);

    if(!publicModulesIsFetching && publicModules === undefined && !publicModulesError)
    {
        dispatch(getPublicStoreModulesAsync())
    }

    const modules = useAppSelector(selectModules);
    const isFetching = useAppSelector(selectModulesIsFetching);
    const error = useAppSelector(selectModulesError);

    const modulesApex = useAppSelector(selectApexModules);
    const isFetchingApex = useAppSelector(selectApexModulesIsFetching);
    const errorApex = useAppSelector(selectApexModulesError);


    const company = useAppSelector(selectCompany);
    const isFetchingCompany = useAppSelector(selectCompanyIsFetching);
    const errorCompany = useAppSelector(selectCompanyError);


    if (!isFetchingCompany && company === undefined && !errorCompany) {
        dispatch(getCompanyAsync());
    }

    
    if (!isFetching && modules === undefined && !error && !roles.includes("Apex") && user && company) {
        dispatch(getStoreModulesAsync(company.uniqueIdentifier));
    }

    if (!isFetchingApex && modulesApex === undefined && !errorApex && roles.includes("Apex") && user)
    {
        dispatch(getAllModulesAsync());
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          setLoadingTimeout(true); // Set the timeout flag to true after 8 seconds
        }, 8000);
    
        return () => clearTimeout(timer); // Clear the timer if the component unmounts
      }, []);

    return (
        <>
            <h1>Solutions</h1>
            <br></br>
            {roles.includes("Apex") && user ?(<>
            {
                !isFetchingApex && modulesApex !== undefined ? (
                    <>
                        {modulesApex.map(item => (
                            <>
                                <StoreLineCard data={item}></StoreLineCard>
                                <br></br>
                            </>
                        ))}
                    </>
                ) : loadingTimeout ? (
                    <div>Failed to retrieve modules</div>
                  ) : (
                    <Spinner animation="border" variant="dark" role="status">
                        <   span className="visually-hidden">Loading...</span>
                    </Spinner>
                )
            }</>) :(<>
            {
                !isFetching && modules !== undefined ? (
                    <>
                        {modules.map(item => (
                            <>
                                <StoreLineCard data={item}></StoreLineCard>
                                <br></br>
                            </>
                        ))}
                    </>
                ) : (<>{publicModules ? (<>
                {publicModules.map(item => (
                            <>
                                <StoreLineCard data={item}></StoreLineCard>
                                <br></br>
                            </>
                        ))}
                </>) : <Spinner animation="border" variant="dark" role="status">
                <   span className="visually-hidden">Loading...</span>
            </Spinner>}
                </>
                    
                )
                
            }</>)
        }
        </>
    )
}
