import "./create-company-modal.scss";
import { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from 'react-router-dom';
import Axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from 'react-bootstrap/Spinner'
import { errorToast, successToast } from "../../../utils/toast-utils";
import { selectCompanyCreationRedirect, updateCompanyCreationRedirect } from "../../../slices/company/company-slice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { getFormValue } from "../../../utils/form-utils";
import countryList from 'react-select-country-list';
import { Address } from "../../../models/company";

export function CreateCompanyModal() {
    const history = useHistory();    
    const dispatch = useAppDispatch();
    const selectedCompanyCreationRedirectUrl = useAppSelector(selectCompanyCreationRedirect);
    const companyCreationRedirectUrl =  selectedCompanyCreationRedirectUrl;
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setValidated(false)
        setShow(true)
    };

    const [validated, setValidated] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    
    const [country, setCountry] = useState('South Africa')
    const countryOptions = useMemo(() => countryList().getData(), [])
    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const name = getFormValue(form, 'formName');
        const currency = getFormValue(form, 'formCurrency');
        const taxNumber = getFormValue(form, 'formTaxNumber');
        const addressStreet = getFormValue(form, 'formStreetAddress');
        const addressCity = getFormValue(form, 'formCity');
        const addressCountry = country;
        const addressPostalCode = getFormValue(form, 'formPostalCode');

        const postModel = {
            name: name,
            currency: currency,
            taxNumber: taxNumber,
            address: {
                street: addressStreet,
                city: addressCity,
                country: addressCountry,
                postalCode: addressPostalCode
            } as Address,
        };
        setValidated(true);
        event.preventDefault();

        try {
            const accessToken = await getAccessTokenSilently();
            const postUrl = `${process.env.REACT_APP_CORE_SERVER_URL}api/company`
            await Axios.post(postUrl, postModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
            successToast(`Company ${postModel.name} created successfully${companyCreationRedirectUrl ? '. You will now be redirected... ' : ''}`)
            handleClose();
            if (companyCreationRedirectUrl){
                dispatch(updateCompanyCreationRedirect(undefined))
                setTimeout(() => {
                    const redirect = companyCreationRedirectUrl.includes("http") ? companyCreationRedirectUrl : "https://"+companyCreationRedirectUrl;
                    window.location.href = redirect;
                }, 5000 )
                return;
            }
            history.push('/companies');
            window.location.reload();
        } catch (error: any) {
            errorToast(error.response.data)
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button variant="outline-secondary" className="m-auto" onClick={handleShow}>
                Create Company
            </Button>
            <Modal show={show} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
                <Modal.Header id="create-company-header">
                    <Modal.Title>Add a New Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="w-100 h-100">
                            <Col>
                                <h4>Company details</h4>
                                <Form.Group className="mb-3" controlId="formName">
                                    <Form.Label>Name *</Form.Label>
                                    <Form.Control type="text" required />
                                    <Form.Control.Feedback type="invalid">Please provide a company name.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCurrency">
                                    <Form.Label>Currency *</Form.Label>
                                    <Form.Select>
                                        <option>ZAR</option>
                                        <option>USD</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formTaxNumber">
                                    <Form.Label>Tax Number *</Form.Label>
                                    <Form.Control type="text" required />
                                    <Form.Control.Feedback type="invalid">Please provide a tax number.</Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        Please note this will appear on any future invoices.
                                    </Form.Text>
                                </Form.Group>
                                <h4>Address</h4>
                                <Row className="w-100">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formStreetAddress">
                                            <Form.Label>Street Name and Number *</Form.Label>
                                            <Form.Control type="text" required/>
                                            <Form.Control.Feedback type="invalid">Please provide a street name and number.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formCity">
                                            <Form.Label>City/Town *</Form.Label>
                                            <Form.Control type="text" required/>
                                            <Form.Control.Feedback type="invalid">Please provide a city or town.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formCountry">
                                            <Form.Label>Country *</Form.Label>
                                            <Form.Select value={country} onChange={handleChangeCountry}>
                                                {countryOptions.map(country => (<option>{country.label}</option>))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">Please provide a country.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPostalCode">
                                            <Form.Label>Postal Code *</Form.Label>
                                            <Form.Control type="text" required className="postal-code"/>
                                            <Form.Control.Feedback type="invalid">Please provide a postal code.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="w-100">
                            <Col md={{ span: 4, offset: 4 }}>
                                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading }>
                                    Cancel
                                </Button>
                            </Col>
                            <Col md={4}>
                                <Button type="submit" className="w-100" disabled={isLoading }>
                                    { isLoading  ? <Spinner size="sm" animation="border" variant="light" /> : <span>Confirm</span>}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
