import "./duplicate-module-modal.scss"
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { selectCompanies, selectCompaniesError, selectCompaniesIsFetching, getAllCompaniesAsync } from "../../../../../slices/apex-data/apex-data-slice";
import { addModule } from "../../../../../slices/apex-data/apex-modules-slice";
import Axios, { AxiosResponse } from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from 'react-bootstrap/Spinner';
import { errorToast, successToast } from "../../../../../utils/toast-utils";
import { getFormValue } from "../../../../../utils/form-utils";
import { FoundationModule } from "../../../../../models/module";

export function DuplicateModuleModal(props) {
  const originalData: FoundationModule = props.data;
  const closeCallback = props.closeCallback;
  const companies = useAppSelector(selectCompanies);
  const isFetching = useAppSelector(selectCompaniesIsFetching);
  const error = useAppSelector(selectCompaniesError);
  const dispatch = useAppDispatch();

  if (!isFetching && companies === undefined && !error) {
      dispatch(getAllCompaniesAsync());
  }

  // const [show, setShow] = useState(props.shown);
  const [isLoading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => {
    // setShow(false)
    closeCallback()
  };

  const [publicFlag, setPublicFlag] = useState(originalData.public);
  const handlePublicFlagChange = (event) => {
    setPublicFlag(event.target.checked)
  };

  //TODO: Might not be needed
  // const [selectedCompany, setSelectedCompany] = useState(undefined);
  // const [selectedCompanyName, setSelectedCompanyName] = useState(undefined);
  // const handleCompanyChange = (event) => {
  //   const options = event.target.options;
  //   const selectedIndex = options['selectedIndex']
  //   setSelectedCompany(options[selectedIndex].value)
  //   setSelectedCompanyName(options[selectedIndex].text)
  // };

  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }

    const name = getFormValue(form, 'formName');
    const url = getFormValue(form, 'formUrl');
    const author = getFormValue(form, 'formAuthor');
    const description = getFormValue(form, 'formDescription');
    const objectiveDescription = getFormValue(form, 'formObjectiveDescription');
    const inputDescription = getFormValue(form, 'formInputDescription');
    const outputDescription = getFormValue(form, 'formOutputDescription');
    // TODO: Check if should be company focused if not public and for one or many

    const postModel = {
      name: name,
      accessUrl: url,
      author: author, 
      description: description,
      objectiveDescription: objectiveDescription,
      inputDescription: inputDescription,
      outputDescription: outputDescription,
      public: publicFlag,
    } as FoundationModule;
    setValidated(true);
    event.preventDefault();

    try {
      const accessToken = await getAccessTokenSilently();
      const postUrl = `${process.env.REACT_APP_MODULES_SERVER_URL}api/module`;
      var res = await Axios.post<any, AxiosResponse<FoundationModule>>(postUrl, postModel, { headers: { Authorization: `Bearer ${accessToken}`, } });
      dispatch(addModule(res.data))
      successToast(`Module created successfully`)
      handleClose();
    } catch (error: any) {
      errorToast(error.response.data)
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={props.shown} onHide={handleClose} backdrop={true} animation={false} dialogClassName="custom-modal-dialog-right" contentClassName="custom-modal-content">
        <Modal.Header id="create-module-header">
          <Modal.Title>Duplicate Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-flex align-items-center justify-content-between h-100 flex-direction-col" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="w-100 h-100">
              <Col>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Module Name*</Form.Label>
                  <Form.Control type="text" required defaultValue={originalData.name + " (Copy)"}/>
                  <Form.Control.Feedback type="invalid">Please provide a name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formUrl">
                  <Form.Label>Module URL*</Form.Label>
                  <Form.Control type="text" required defaultValue={originalData.accessUrl}/>
                  <Form.Control.Feedback type="invalid">Please provide a URL.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAuthor">
                  <Form.Label>Module Author*</Form.Label>
                  <Form.Control type="text" required defaultValue={originalData.author}/>
                  <Form.Control.Feedback type="invalid">Please provide an author.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>Module Description*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.description}/>
                  <Form.Control.Feedback type="invalid">Please provide a module description.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formObjectiveDescription">
                  <Form.Label>Description of module objectives*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.objectiveDescription}/>
                  <Form.Control.Feedback type="invalid">Please provide a description of the module objectives.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formInputDescription">
                  <Form.Label>Description of possible inputs*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.inputDescription}/>
                  <Form.Control.Feedback type="invalid">Please provide a description of the possible inputs.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOutputDescription">
                  <Form.Label>Description of possible outputs*</Form.Label>
                  <Form.Control as="textarea" rows={4} required defaultValue={originalData.outputDescription}/>
                  <Form.Control.Feedback type="invalid">Please provide a description of the possible outputs.</Form.Control.Feedback>
                </Form.Group>

                <Row className="w-100">
                  <Col>
                    <Form.Group className="mb-3" controlId="formPublicFlag">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Public module"
                        defaultChecked={publicFlag}
                        onChange={handlePublicFlagChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="w-100">
              <Col md={{ span: 4, offset: 4 }}>
                <Button variant="outline-secondary" className="w-100" onClick={handleClose} disabled={isLoading}>
                  Cancel
                </Button>
              </Col>
              <Col md={4}>
                <Button type="submit" className="w-100" disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : <span>Confirm</span>}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

