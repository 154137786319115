import "./create-company-page.scss"

import { useEffect, useState } from "react";

import Axios from "axios";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container"
import { CreateCompanyModal } from "./create-company-modal/create-company-modal";
import { JoinWithCodeModal } from "./join-with-code-modal/join-with-code-modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import qs from "qs"
import { updateCompanyCreationRedirect } from "../../slices/company/company-slice";
import { useAppDispatch } from "../../app/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from 'react-router-dom';

export function CreateCompanyPage() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently, user } = useAuth0();

    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    let redirectUrl: string | undefined = queryParams.redirectUrl as string | undefined;
    if (user && user["https://foundation.com/app_metadata"] && user["https://foundation.com/app_metadata"]["root_company_identifier"]) {
        if (redirectUrl) {
            window.location.href = redirectUrl.includes("http") ? redirectUrl : "https://" + redirectUrl;
        } else {
            history.push('/home');
            window.location.reload();
        }
    }
    dispatch(updateCompanyCreationRedirect(redirectUrl))

    const [inviteCheckIsLoading, setInviteCheckIsLoading] = useState(false);
    const [hasActiveInvite, setHasActiveInvite] = useState(false);

    useEffect(() => {
        async function getActiveInviteCheck() {
            setInviteCheckIsLoading(true)
            const token = await getAccessTokenSilently();
            const serverUrl = process.env.REACT_APP_CORE_SERVER_URL;
            try {
                const response = await Axios.get<boolean>(`${serverUrl}api/user/activeInvite`, { headers: { Authorization: `Bearer ${token}`, } });
                const responseData = response.data;
                setHasActiveInvite(responseData);
            } catch (error: any) { } finally { setInviteCheckIsLoading(false) }
        }
        if (!inviteCheckIsLoading)
            getActiveInviteCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container fluid className="text-center h-100 p-5 d-flex flex-direction-col justify-content-evenly">
            <Row >
                <h1>Welcome to the Maluti-X Solutions Portal</h1>
            </Row>
            <Row >
                {
                    user && user.email_verified ? 
                 <h4>It seems you do not have a company linked to your user profile. <br />
                    Please create one below or alternatively you can use company joining code if you have one.</h4> 
                    :<></>}
            </Row>
            <Row className="w-50 mx-auto">
                {
                    user && user.email_verified ? (<>
                        {inviteCheckIsLoading ?
                            <Col >
                                <Spinner animation="border" variant="dark" role="status">
                                    <   span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </Col>
                            :
                            <> {
                                hasActiveInvite ?
                                    <Col >
                                        <JoinWithCodeModal></JoinWithCodeModal>
                                    </Col> :
                                    <></>
                            }

                                <Col >
                                    <CreateCompanyModal></CreateCompanyModal>
                                </Col>
                            </>
                        }

                    </>) :
                        (<>
                            {/* <hr /> */}
                            <h4>In order to create or join a company you need to verify your email. Please verify your email before continuing.</h4>
                        </>)
                }

            </Row>
            <Row >
                {redirectUrl ? <p>After being assigned a company you will be redirected to <b>{redirectUrl}</b></p> : <></>}
            </Row>
        </Container>
    );
}