import "./loading.scss"
import { motion } from 'framer-motion'
export function Loading() {

  const variants = {
    hidden: {
      pathLength: 0
    },
    stage1: i => ({
      pathLength: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
      transition: {
        delay: i / (14/5),
        duration: 5,
        repeat: Infinity,
      },
    }),
    stage2: i => ({
      pathLength: [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0],
      transition: {
        delay: i / (14/5),
        duration: 5,
        repeat: Infinity,
      },
    }),
    stage3: i => ({
      pathLength: [0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
      transition: {
        delay: i / (14/5),
        duration: 5,
        repeat: Infinity,
      },
    }),
    stage4: i => ({
      pathLength: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
      transition: {
        delay: i / (14/5),
        duration: 5,
        repeat: Infinity,
      },
    }),
    stage5: i => ({
      pathLength: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
      transition: {
        delay: i / (14/5),
        duration: 5,
        repeat: Infinity,
      },
    }),
    stage6: i => ({
      pathLength: [0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0],
      transition: {
        delay: i / (14/5),
        duration: 5,
        repeat: Infinity,
      },
    }),
    stage7: i => ({
      pathLength: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
      transition: {
        delay: i / (14/5),
        duration: 5,
        repeat: Infinity,
      },
    }),
  }

  return (
    <div id="svg-container">



      <motion.svg
        width="100%" height="50%" viewBox="0 0 612 792">
        <g>
          <motion.path fill="none" stroke="#43AFAC" strokeWidth="5"
            d="M-216 459, L-120 382"
            variants={variants}
            custom={1}
            initial="hidden"
            animate="stage1"
          />
          <motion.path fill="none" stroke="#43AFAC" strokeWidth="5"
            d="M-120 382, L-6 352"
            variants={variants}
            custom={2}
            initial="hidden"
            animate="stage2"
          />
          <motion.path fill="none" stroke="#43AFAC" strokeWidth="5"
            d="M-7 352, L167 210"
            variants={variants}
            custom={3}
            initial="hidden"
            animate="stage3"
          />
          <motion.path fill="none" stroke="#43AFAC" strokeWidth="5"
            d="M164 210, L227 281"
            variants={variants}
            custom={4}
            initial="hidden"
            animate="stage4"
          />
          <motion.path fill="none" stroke="#43AFAC" strokeWidth="5"
            d="M226 280, L330 281"
            variants={variants}
            custom={5}
            initial="hidden"
            animate="stage5"
          />
          <motion.path fill="none" stroke="#43AFAC" strokeWidth="5"
            d="M328 280, L616 532"
            variants={variants}
            custom={6}
            initial="hidden"
            animate="stage6"
          />
          <motion.path fill="none" stroke="#43AFAC" strokeWidth="5"
            d="M614 532, L895 389"
            variants={variants}
            custom={7}
            initial="hidden"
            animate="stage7"
          />

        </g>
      </motion.svg>
    </div>
  );
}
