import { toast } from "react-toastify";

export function defaultToast(message: string) {
    toast(message);
}

export function infoToast(message: string) {
    toast.info(message);
}

export function successToast(message: string) {
    toast.success(message);
}

export function warningToast(message: string) {
    toast.warning(message);
}

export function errorToast(message: string) {    
    toast.error(message);
}