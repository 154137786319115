import "./modules.scss"

import { getAllModulesAsync, selectApexModules, selectApexModulesError, selectApexModulesIsFetching } from "../../../slices/apex-data/apex-modules-slice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"

import { AddModuleModal } from "./add-module-modal/add-module-modal"
import { CategoriesModal } from "./categories-modal/categories-modal"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { ModuleLineCard } from "./module-line-card/module-line-card";
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"

export function ModulesView() {


    const modules = useAppSelector(selectApexModules);
    const isFetching = useAppSelector(selectApexModulesIsFetching);
    const error = useAppSelector(selectApexModulesError);
    const dispatch = useAppDispatch();

    if (!isFetching && modules === undefined && !error ) {
        dispatch(getAllModulesAsync());
    }

    return (
        <Container fluid>
            
            <br/>
            <Row className="w-100">
                <Col md={3}>
                    <h1>Solutions</h1>
                </Col>
                <Col md={{ span: 4, offset: 5 }} className="d-flex">
                    <CategoriesModal></CategoriesModal>
                    <AddModuleModal></AddModuleModal>
                </Col>
            </Row>
            <br />
            {!isFetching && modules !== undefined ? (
               <>
                {modules.map(item => (
                    <>
                            <ModuleLineCard data={item}></ModuleLineCard>
                            <br></br>
                    </>
                        ))}
                </>
            ) : (
                <Spinner animation="border" variant="dark" role="status">
                    <   span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </Container>
    )
}
