import "./module-line-card.scss"

import { errorToast, successToast } from "../../../../utils/toast-utils";

import Axios from "axios";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { DuplicateModuleModal } from "./duplicate-module-modal/duplicate-module-modal";
import { EditModuleModal } from "./edit-module-modal/edit-module-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FoundationModule } from "../../../../models/module";
import { ModuleCompanyRelationship } from "../../../../models/module-company-relationship";
import Row from "react-bootstrap/Row";
import Spinner from 'react-bootstrap/Spinner';
import { removeModule, } from "../../../../slices/apex-data/apex-modules-slice";
import { useAppDispatch } from '../../../../app/hooks';
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export function ModuleLineCard(props) {
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const moduleData: FoundationModule = props.data;
    const [updatedModuleData, setUpdatedModuleData] = useState(moduleData);

    const [deleteIsLoading, setDeleteLoading] = useState(false);
    const [editModalShown, setEditModalShown] = useState(false);
    const [duplicateModalShown, setDuplicateModalShown] = useState(false);

    const [expanded, setExpanded] = useState(false);
    const handleExpandedChange = () => {
        setExpanded(!expanded)
    };

    const handleEditModule = async () => {
         const moduleIdentifier = moduleData.uniqueIdentifier;
         const token = await getAccessTokenSilently();

         const serverUrl = process.env.REACT_APP_MODULES_SERVER_URL;
         try {
             const response = await Axios.get<ModuleCompanyRelationship[]>(`${serverUrl}api/module/modulesCompanyRelationship/${moduleIdentifier}`, { headers: { Authorization: `Bearer ${token}`, } });
    
             const responseData = response.data;

             setUpdatedModuleData({...moduleData, moduleCompanyRelationships : response.data})

            setEditModalShown(true) 

             return responseData;
         } catch (error: any) {
             errorToast(error.response.data)
             throw error;
         }

    }

    const handleDuplicateModule = async () => {
        setDuplicateModalShown(true)
    }

    const editModuleCloseCallback = async () => {
        setEditModalShown(false)
    }

    const duplicateModuleCloseCallback = async () => {
        setDuplicateModalShown(false)
    }


    const handleDeleteModule = async () => {
        try {
            setDeleteLoading(true)
            const accessToken = await getAccessTokenSilently();
            const response = await Axios.delete<string>(`${process.env.REACT_APP_MODULES_SERVER_URL}api/module/${moduleData.uniqueIdentifier}`, { headers: { Authorization: `Bearer ${accessToken}`, } });
            dispatch(removeModule(response.data))
            successToast(`Module ${moduleData.name} successfully deleted.`)
        } catch (error: any) {
            errorToast(error.response.data)
        } finally {
            setDeleteLoading(false);
        }
    }

    

    return (
        <>
            <Card className={"module-card " + (expanded ? "expanded" : "")}>
                <Card.Body>
                    <Row>
                        <Col md={9}>
                            <Row className="module-name">
                                {moduleData.name}
                            </Row>
                        </Col>
                        <Col md={2} className="dropdown-col">
                            <Dropdown className="d-inline mx-2">
                                <Dropdown.Toggle id="dropdown-autoclose-true">
                                    Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleEditModule}><FontAwesomeIcon icon="edit" className="icon" /> View/Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={handleDuplicateModule}><FontAwesomeIcon icon="clone" className="icon" /> Duplicate</Dropdown.Item>
                                    <Dropdown.Item onClick={handleDeleteModule} disabled={deleteIsLoading}><FontAwesomeIcon icon="trash-alt" className="icon" /> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={1} className="expand-toggle-col" >
                            {
                                deleteIsLoading ?
                                    <Spinner size="sm" animation="border" variant="dark" style={{ margin: 'auto' }} /> :
                                    <FontAwesomeIcon icon="chevron-down" className={"icon expand-toggle " + (expanded ? "expanded" : "")} onClick={handleExpandedChange} />
                            }
                        </Col>
                    </Row>
                    <Row className={"expandable-row " + (expanded ? "expanded" : "")}>
                        <Row>
                            <Col>
                                {moduleData.public ? <span className="public">Public Module</span> : <span className="private">Private Module</span>}
                            </Col>
                            <Col>
                                Author: {moduleData.author}
                            </Col>
                            <Col>
                                Created by: {moduleData.createdBy}
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                Categories will go here
                            </Col>
                            <Col>
                                ID for Contentful : {moduleData.uniqueIdentifier}
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                {moduleData.description}
                            </Col>
                            <Col>
                                {moduleData.objectiveDescription}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {moduleData.inputDescription}
                            </Col>
                            <Col>
                                {moduleData.outputDescription}
                            </Col>
                        </Row> */}
                    </Row>
                </Card.Body>
            </Card>
            <EditModuleModal shown={editModalShown} data={updatedModuleData} closeCallback={editModuleCloseCallback}></EditModuleModal>
            <DuplicateModuleModal shown={duplicateModalShown} data={moduleData} closeCallback={duplicateModuleCloseCallback}></DuplicateModuleModal>
        </>
    );
}