import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../models/user";

export function GetDarkTheme() {
    const { user } = useAuth0();
    return user && user["https://foundation.com/user_metadata"] && user["https://foundation.com/user_metadata"]["dark_theme"];
}

export function GetUserData(): User {
    const { user } = useAuth0();
    return {
        name: user?.nickname ?? "user",
        jobTitle: ["https://foundation.com/user_metadata"]["job_title"] ?? "No job title set",
        phoneNumber: user?.phone_number ?? "Not phone number set",
        country: ["https://foundation.com/user_metadata"]["country"] ?? "No country set",
    } as User
}