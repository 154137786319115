import "./landing-page.scss"

import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { useAuth0 } from "@auth0/auth0-react"

export function LandingPage() {
    const { user } = useAuth0();
    const userName = (user?.name ?? 'User').charAt(0).toUpperCase() + (user?.name ?? 'User').slice(1);

    
    return (
        <Container fluid className="h-100 p-5">
            
            <Row className="justify-content-centre"><h1>Welcome to the Maluti-X Solutions Portal, {userName}!</h1> </Row>
            <br />
            <br/>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>Some widget</Card.Header>
                        <Card.Body>
                            <Card.Title>Some title</Card.Title>
                            <Card.Text>
                                Widget content
                            </Card.Text>
                            <Button variant="primary">Go somewhere</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>Another widget</Card.Header>
                        <Card.Body>
                            <Card.Title>Another title</Card.Title>
                            <Card.Text>
                                Another widget content
                            </Card.Text>
                            <Button variant="primary">Go somewhere else</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>Another widget</Card.Header>
                        <Card.Body>
                            <Card.Title>Another title</Card.Title>
                            <Card.Text>
                                Another widget content
                            </Card.Text>
                            <Button variant="primary">Go somewhere else</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>Another widget</Card.Header>
                        <Card.Body>
                            <Card.Title>Another title</Card.Title>
                            <Card.Text>
                                Another widget content
                            </Card.Text>
                            <Button variant="primary">Go somewhere else</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}